.a1-layout
  width: 100%
  overflow: hidden
  text-align: center
  background-color: white

.a1-lead-in
  height: 950px
  @media $bp.desktopMedium, $bp.desktopLarge
    background: url('https://a.bmstatic.com/iu/134/151/Group 1149-min-2ef4c657b6e76cd6565694d9bed2ac76.png') no-repeat -230px -170px / contain, url('https://a.bmstatic.com/iu/82/65/A1_Logo_Std_Red_Pos_3_L 1-309d15872a53be1ca1d5c1778e0119cd.png') no-repeat right -20px / 250px
  @media $bp.desktop
    background: url('https://a.bmstatic.com/iu/134/151/Group 1149-min-2ef4c657b6e76cd6565694d9bed2ac76.png') no-repeat -360px -170px / contain, url('https://a.bmstatic.com/iu/82/65/A1_Logo_Std_Red_Pos_3_L 1-309d15872a53be1ca1d5c1778e0119cd.png') no-repeat right -20px / 250px
  @media $bp.tablet
    background: url('https://a.bmstatic.com/iu/134/151/Group 1149-min-2ef4c657b6e76cd6565694d9bed2ac76.png') no-repeat -430px -170px / contain, url('https://a.bmstatic.com/iu/82/65/A1_Logo_Std_Red_Pos_3_L 1-309d15872a53be1ca1d5c1778e0119cd.png') no-repeat 110% -20px / 250px
  @media $bp.mobile
    height: fit-content

.a1-caption
  margin: auto
  @media $bp.tablet
    max-width: 380px
  @media $bp.mobile
    max-width: calc(100% - 48px)

.a1-column
  max-width: 505px
  margin: auto
  padding: 80px 0 0 
  @media $bp.tablet
    max-width: 400px

  > form > .button_inline
    width: 338px
    @media screen and (max-width: 374px)    
      width: calc(100% - 48px)

.a1-heading
  position: relative
  &.heading_1
    font-size: 44px
    line-height: 1.4
    @media $bp.mobile
      max-width: calc(100% - 48px)
      margin: auto

  .a1-heading-logos
    top: 5px
    left: 53px
    height: 48px
    width: 358px
    position: absolute
    background: url('https://a.bmstatic.com/iu/152/118/Group 1151-a149a374fda6fdfc36d5e6f87b2ec7e1.png') no-repeat center / contain 
    @media $bp.tablet
      left: 10px

.a1-logos
  @media $bp.mobile
    width: 320px

.a1-decor
  @media $bp.mobile
    width: 100%
    height: 600px
    background: url('https://a.bmstatic.com/iu/23/95/Group 1150-d15728a80b57e341fc3caea4b475f0c1.png') no-repeat center / cover 

.a1-benefits
  margin: -200px 0

.a1-benefits-pair
  display: grid
  height: 360px
  grid-template-columns: 1fr 1fr
  text-align: left
  color: white
  @media $bp.mobile
    min-height: 470px
    grid-template-columns: 1fr
    grid-template-rows: 1fr 2fr
    text-align: center

  .a1-benefit__lead-in
    $font-size-x-large()
    font-weight: bold
    margin: 0

  .a1-benefit
    @media $bp.tablet
      padding: 40px 60px

  &:nth-child(1) .a1-benefit
    background-color: #FF3838
    padding: 80px 30% 80px 130px
    @media $bp.desktop
      padding: 80px 25% 80px 12%
    @media $bp.tablet
      padding: 60px 15% 60px 12%
    @media $bp.mobile
      padding: 24px 48px
  &:nth-child(2) .a1-benefit
    background-color: #9D7760
    padding: 80px 130px 80px 30%
    @media $bp.desktop
      padding: 80px 12% 80px 25%
    @media $bp.tablet
      padding: 60px 12% 60px 15%
  &:nth-child(3) .a1-benefit
    background-color: #3CBB8B
    padding: 80px 30% 80px 130px
    @media $bp.desktop
      padding: 80px 25% 80px 12%
    @media $bp.tablet
      padding: 60px 15% 60px 12%
  &:nth-child(4) .a1-benefit
    background-color: #FF7800
    padding: 80px 130px 80px 30%
    @media $bp.desktop
      padding: 80px 12% 80px 25%
    @media $bp.tablet
      padding: 60px 12% 60px 15%

  &:nth-child(1) .a1-benefit-cover
    background: url('./first.png') no-repeat center / cover
  &:nth-child(2) .a1-benefit-cover      
    background: url('./second.png') no-repeat center / cover
  &:nth-child(3) .a1-benefit-cover      
    background: url('./third.png') no-repeat center / cover
  &:nth-child(4) .a1-benefit-cover      
    background: url('./forth.png') no-repeat center / cover

.a1-carousel
  position: relative

.carousel__pager
  position: absolute
  bottom: 24px
  left: calc((100% - 74px) / 2)

.carousel__frame > div > div
  @media $bp.mobile
    &:nth-child(1) .a1-benefit
      background-color: #FF3838
    &:nth-child(2) .a1-benefit
      background-color: #9D7760
    &:nth-child(3) .a1-benefit
      background-color: #3CBB8B
    &:nth-child(4) .a1-benefit
      background-color: #FF7800

    &:nth-child(1) .a1-benefits-pair .a1-benefit-cover
      background: url('./first.png') no-repeat center / cover
    &:nth-child(2) .a1-benefits-pair .a1-benefit-cover
      background: url('./second.png') no-repeat center / cover
    &:nth-child(3) .a1-benefits-pair .a1-benefit-cover
      background: url('./third.png') no-repeat center / cover
    &:nth-child(4) .a1-benefits-pair .a1-benefit-cover
      background: url('./forth.png') no-repeat center / cover

.a1-code-input
  box-sizing: border-box
  width: 338px
  height: 56px
  border: 1px solid $base-disabled
  border-radius: 4px
  padding: 16px
  text-align: center
  @media screen and (max-width: 374px)    
    width: calc(100% - 48px)
  &::placeholder
    text-align: center

  &_error
    $font-size-x-small()
    color: $text-error
    background: rgba(255, 255, 255, 0.7)
    border-radius: 2px
    max-width: 338px
    @media screen and (max-width: 374px)    
      width: calc(100% - 48px)
    margin: auto

.a1-footer
  color: white
  padding: 80px 0 20px
  background: url(https://a.bmstatic.com/iu/40/250/mincove-015c094f845b078f9d42831e69042dbe.png) no-repeat left top/cover #000
  @media $bp.desktopLarge, $bp.desktopMedium
    margin-top: 200px
    background: url(https://a.bmstatic.com/iu/40/250/mincove-015c094f845b078f9d42831e69042dbe.png) no-repeat left top/75% 100% #000
  @media $bp.desktopMedium
    margin-top: 200px
    background: url(https://a.bmstatic.com/iu/40/250/mincove-015c094f845b078f9d42831e69042dbe.png) no-repeat left top/80% 100% #000
  @media $bp.desktop
    margin-top: 200px
    background: url(https://a.bmstatic.com/iu/40/250/mincove-015c094f845b078f9d42831e69042dbe.png) no-repeat left top/85% 100% #000
  @media $bp.tablet
    margin-top: 180px

  > .heading_2
    font-size: 44px
    line-height: 1.4
    max-width: 440px
    margin: auto

.a1-success__wrapper
  background-color: white

  > .code-success-layout
    background-color: white
