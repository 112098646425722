for size in $cover-sizes
  .comicbook_{size}
    width: (size)px

.comicbook
  position: relative

.comicbook_hoverable
  &:hover
    box-shadow: 0 0 0 1px $base-line-accent

.comicbook__authors
  position: relative
  z-index: 1

.comicbook__title
  $list-view-title-link()
  display: block

.comicbook__cover
  position: relative
  display: flex
  flex-grow: 0
  flex-shrink: 0

.comicbook__description
  line-height: .8

.comicbook_list
  list-style-type: none
  $list-view()
  padding: 12px 8px 12px 16px

  .comicbook__description
    $list-view-info()
    min-width: 0

  .comicbook__title
    $list-view-title()

  .comicbook__reading-status
    $list-view-reading-status()

.comicbook_grid
  $grid-view()

  .comicbook__title
    $grid-view-title()

  .comicbook__description
    $grid-view-description()

  .comicbook__reading-status
    $grid-view-reading-status()

.comicbook__position
  $position-label()
