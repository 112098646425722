$centred = translate(-50%, -50%)

.popup
  box-sizing: border-box
  width: 392px
  max-width: 100%
  padding: 24px 40px 8px
  background: white
  border-radius: 40px
  position: fixed
  z-index: 10
  top: 50%
  left: 50%
  transform: $centred
  animation: zoomIn 0.2s

  &_closing
    animation: zoomOut 0.2s

  &__close
    display: none
    position: absolute
    right: 16px
    top: @right
    width: 16px
    height: @width
    fill: $text-secondary
    z-index: 1
    cursor: pointer

  @media $bp.mobile
    height: 100%
    width: 100%
    padding: 0 8px 0
    border-radius: 0

    &__close
      display: block

@keyframes zoomIn
  0%
    opacity: 0
    transform: $centred scale(0.7)

  100%
    opacity: 1
    transform: $centred scale(1)

@keyframes zoomOut
  0%
    opacity: 1
    transform: $centred scale(1)

  100%
    opacity: 0
    transform: $centred scale(0.7)

.shelves-navigation__mobile
  height: 70%
  margin-top: 30%
  border-radius: 8px 8px 0px 0px
  overflow-y: scroll

.mtn_background
  background: #FFCB05

.yettel_background
  background: rgba(180, 255, 0, 1)

.auth__recaptcha
  color: $text-secondary
  > a 
    color: black
