.logo
  display: block
  width: 32px
  height: @width
  flex: 0 0 @width

  &_size_24
    width: 24px
    height: @width
    flex-basis: @width

  &_size_40
    width: 40px
    height: @width
    flex-basis: @width

  &_size_100
    width: 100px
    height: @width
    flex-basis: @width

  &_size_176
    width: 176px
    height: @width
    flex-basis: @width

  &_kind
    &-family
      display: flex
      align-items: flex-end

  &__item
    margin-right: 8px
    animation: jump .5s linear infinite
    animation-play-state: paused

    &:last-child
      margin-right: 0

    &:hover
      animation-play-state: running

@keyframes jump {
  from {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -10px);
  }

  to {
    transform: translate(0, 0);
  }
}
