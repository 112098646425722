.error-cartoon__image
  svg
    width: 300px
    height: 326px

  @media $bp.mobile
    svg
      width: 240px
      height: 270px


.error-cartoon__left-eyebrow
.error-cartoon__right-eyebrow
  animation: error-cartoon-eyebrow-wiggle 2s cubic-bezier(0, 0.52, 0, 1.32) infinite
  animation-delay: 2s

.error-cartoon__arms-outline
.error_cartoon__arms-fill
  animation: error-cartoon-shrug 2s cubic-bezier(0, 0.52, 0, 1.32) infinite
  animation-delay: 2s


@keyframes error-cartoon-shrug
  // start with raised shoulders
  0%
    transform: translate(-143.82px, -342px)
  // shoulders go down
  10%
    transform: translate(-143.82px, -338px)
  // shoulders go up
  20%
    transform: translate(-143.82px, -342px)
  // shoulders stay raised
  100%
    transform: translate(-143.82px, -342px)

@keyframes error-cartoon-eyebrow-wiggle
  // start with slightly raised eyebrows
  0%
    transform: translate(-143.82px, -343.74px)
  // eyebrows move down
  10%
    transform: translate(-143.82px, -342.5px)
  // eyebrows move up
  14%
    transform: translate(-143.82px, -343.74px)
  // eyebrows overshoot their final top position
  16%
    transform: translate(-143.82px, -344.6px)
  // eyebrows return to their final top position
  18%
    transform: translate(-143.82px, -343.74px)
  // eyebrows stay at their final position
  100%
    transform: translate(-143.82px, -343.74px)
