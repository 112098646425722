.side-menu__links
  padding: 0
  margin: 0 0 16px
  list-style: none

.side-menu__item
  display: inline-block

.side-menu__link
  $link-underline-on-hover()
  $font-size-x-small()
  margin-right: 16px
