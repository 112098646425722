.overlay
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1100
  background: rgba(black, 0.7)
  animation: fadeIn 0.2s

  &_closing
    opacity: 0
    transition: opacity 0.2s

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
