.auth
  position: relative
  color: $text-secondary
  padding: 16px

  @media $bp.mobile
    position: static
    padding: 0
    padding-top: 32px

  &__header
    $font-size-large()
    text-align: center
    color: $text-primary
    margin: 0 0 40px

    @media $bp.mobile
      $font-size-medium()
      margin-bottom: 24px

  &__link
    color: inherit
    cursor: pointer
    text-decoration: underline

    &:hover
      color: $text-accent

  &__subaction
  &__legal
  &__instruction
    text-align: center

  &__instruction
    margin: -28px 0 0

    @media $bp.mobile
      margin: 0

  &__subaction
    $font-size-small()
    margin: 32px 0 16px

  &__legal
    $font-size-xx-small()
    opacity: 0.8
    margin: 28px 0 0

    @media $bp.mobile
      width: 100%
      margin: 32px 0

  &__error
    text-align: center
    color: $text-error

  &__error-link
    text-decoration: underline
    cursor: pointer

  &__back
    position: absolute
    top: 8px
    left: -30px
    width: 40px
    height: @width
    fill: $text-secondary
    cursor: pointer

    @media $bp.mobile
      left: 0
      width: 32px
      height: @width

  &__success-mark
    display: block
    margin: 0 auto
    width: 180px
    height: @width
    fill: $text-success

    @media $bp.mobile
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

  &__step
    animation: fadeIn 0.6s

  &__recaptcha
    $font-size-xx-small()
    text-align: center

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.mtn_background
  .auth__instruction
    color: black

.ghana_button
  border-radius: 28px

.button_submit.ghana_button
  color: white
  background: #000

.button_disabled.ghana_button
  background: #EDEDED
  color: rgba(0, 0, 0, 0.20)

.auth__subaction
  color: black

.popup
  .overlay
    position absolute
    inset 0
    display flex
    justify-content center
    align-items center
    background-color #ffcb05AA
    border-radius 40px
    overflow hidden

