.horizontal-scroller
  overflow-x: auto
  overflow-y: hidden
  white-space: nowrap
  padding: 0 0 12px 0
  list-style-type: none
  display: flex
  -webkit-overflow-scrolling: touch

  &_item
    display: inline-block
    &:not(:last-child)
      margin-right: 24px
    &.reduced-margins
      margin-right: 12px
    &.series
      padding: 0
      &:first-child
        margin-left: 8px
      @media $bp.desktop
        width: calc(50% - 20px)
      @media $bp.desktopMedium, $bp.desktopLarge
        width: calc(50% - 12px)

  & .comicbook,
    .book,
    .book-cover
      width: auto

  & .comicbook__description,
    .book__description
      display: flex
      flex-direction: column
      justify-content: flex-start
      min-width: 100px

.slider__mobile-wrapper
  > .horizontal-scroller
    display: block
