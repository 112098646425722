.more-button
  display: inline-block
  vertical-align: top
  padding-bottom: 1px

  &_centered
    display: block

    .button
      display: table
      margin: 0 auto

  .button
    $font-size-xx-small()
    width: auto
    min-height: 40px
    padding: 12px 32px
    line-height: 14px
    color: $text-primary
    text-transform: uppercase
    background-color: white
    border-radius: 20px
    box-shadow: 0 1px 0 0 #ddd6cf
    -webkit-mask-image: -webkit-radial-gradient(white, black)

    &_loading
      background-color: transparent
      box-shadow: none

      .loader circle
        stroke: $text-secondary

.more-button .button:not(.button_disabled):not(.button_loading):hover
  background-color: $base-disabled
  color: $text-primary
