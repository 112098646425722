.library-banner
  position: relative
  background-position: center
  background-size: cover

.library-banner__content
  display: flex
  flex-direction: column
  width: 100%
  height: 192px
  font-family: $font-graphik
  justify-content: center
  align-items: center

.library-banner__title
  $font-size-large()
  line-height: 1.4em
  font-family: $font-kazimir
  margin: 0 0 1em 0
  text-align: center
  white-space: pre-wrap

.library-banner__button
  $font-size-small()
  display: inline-block
  font-weight: bold
  line-height: 1
  margin: 0 8px 8px
  padding: 12px 20px
  border-radius: 40px
  vertical-align: middle
  text-decoration: none

.library-banner__close
  position: absolute
  top: 0
  right: 0
  width: 16px
  height: 16px
  padding: 16px
  opacity: 0.5
  z-index: 5
  cursor: pointer

  &:hover
    opacity: 1
