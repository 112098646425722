.sorting-selected
  font-family: $font-graphik
  $font-size-x-small()
  padding: 4px 10px
  display: inline-flex
  width: fit-content
  align-items: center
  outline: none
  background: inherit
  cursor: pointer
  position: relative
  color: black
  border: none
  &:focus,
  &:hover
    color: black

  .dropdown
    margin: 0
    padding: 0
    width: 155px
    border-radius: 4px
    background: #F7F7F7
    box-sizing: border-box
    &::before
      display: none

    @media $bp.mobile
      right: -20px
      top: 35px

  .dropdown_hidden
    left: inherit

  .sorting-chevron 
    width: 16px
    height: @width
    margin-left: 8px
    > svg
      width: 16px
      height: @width

  .option
    padding: 16px 10px
    display: flex
    justify-content: space-between
    &:hover
      color: $action-accent
      transition: all 0.2s
      cursor: pointer
      .sorting-order path
        fill: $action-accent

    &.selected
      background: $action-success
      color: white
      .sorting-order path
        fill: white

  .selected-tick
    margin-right: 9px
    opacity: 0

    &.visible
      opacity: 1

.sorting-order
  margin-left: 13px
  display: inline-block
  &.desc
    transform: rotate(180deg)
    transition: transform 0.3s

.sorting-title
  color: $text-secondary
  $font-size-x-small()

.chevron-icon > span > svg
  fill: $text-secondary
  > path
    fill: $text-secondary

.chevron-icon > .sorting-order
  margin: 0

.sorting_wrapper
  display: flex
  align-items: center

.option 
  .sorting-order 
    position: absolute
    right: 10px
    path
      fill: black
