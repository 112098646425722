.copyright
  $font-size-small()
  font-weight: bold
  color: $text-primary
  padding-bottom: 64px

  &_color
    &-secondary
      color: $text-secondary

.copyright_centered
  text-align: center
