$kinds = {
  facebook: $social-facebook
  tw: $social-twitter
  primary: $action-success,
  danger: $action-error,
  telenor_bulgaria: #002340,
  apple: $social-apple
}

.button
  $btn-primary()

  &:focus
    outline: none
  &:active
    opacity: 0.9

  &:not(&_disabled):not(&_loading):hover
    color: white
    background-color: darken($text-accent, 20%)
    border: 1px solid transparent

  body &_loading
    cursor: default

  body &_disabled
    cursor: default
    background-color: $base-line-secondary

  &__icon
    position: absolute
    top: 50%
    left: 9px
    transform: translateY(-50%)
    width: 24px
    height: @width

  &__inline-icon
    display: inline-block
    width: 18px
    height: @width
    margin-top: -2px
    margin-right: 4px
    vertical-align: middle

  for $kind, $color in $kinds
    &_{$kind}
      background-color: $color

      &:not(^[0]_disabled):not(^[0]_loading):hover
        background-color: darken(@background-color, 20%)

  &_google
    background-color: $social-google
    color: white

    path
      fill: white

    &:not(^[0]_disabled):not(^[0]_loading):hover
      background-color: darken(@background-color, 20%)

  &_telenor_bulgaria
    color: #B4FF00
    path
      fill: #B4FF00

  &_transparent
    color: $text-primary
    background-color: transparent
    border: 1px solid $text-primary

    &.button_disabled
      background-color: transparent
      border-color: $base-line-secondary

    &:not(^[0]_disabled):not(^[0]_loading):hover
      color: $text-accent
      background-color: @background-color
      border-color: $text-accent
      fill: $text-accent

  &_icon
    background-color: transparent
    border-color: transparent

    &:not(.button_disabled):not(.button_loading):hover
      background-color: transparent
      border-color: transparent

  &_hidden
    opacity: 0
    transition: opacity 0.3s

  &_submit
    margin: 24px 0 0

  &_inline
    width: auto
    display: inline-block
    align-self: baseline

  &_huge
    $btn-huge()

  &_secondary
    $font-size-xx-small()
    display: inline-block
    width: auto
    min-width: 144px
    font-weight: bold
    padding: 8px 16px
    text-transform: uppercase
    border: 1px solid rgba($base-line-secondary, 0.5)

    svg:not(.dropdown__item-icon-svg)
      width: 32px
      height: 32px
      fill: $text-accent

    &.button_disabled
      color: $text-secondary
      cursor: default

      svg:not(.dropdown__item-icon-svg)
        fill: $text-secondary

.button_dark
.button_dark:not(.button_disabled):not(.button_loading):hover
  color: white
  background-color: rgba(0, 0, 0, 0.2)
  border: none
  fill: white

.button_dark:not(.button_disabled):not(.button_loading):hover
  background-color: rgba(0, 0, 0, 0.5)

.button_square
  padding: 0
  width: 48px
  height: @width
  border-color: $base-line-secondary

.button_square .context-menu
  display: block
  padding-top: 8px

  @media screen and (max-width: 299px)
    padding-top: 18px

.button_square .context-menu > span svg
  width: 32px
  height: @width
  fill: $text-accent

.button_square:hover
  border-color: $text-accent

.button_expand
  width: 48px
  border: none !important

.button_card-footer
.button_card-footer:not(.button_disabled):not(.button_loading):hover
  height: 36px
  padding: 0
  border: none
  min-height: auto

.button_card-footer .context-menu > span svg
  width: 32px
  fill: $text-secondary

.button_link
  font: inherit
  background: none
  border: none
  color: inherit
  cursor: inherit
  display: inherit
  justify-content: inherit
  width: 100%

  &:focus
    outline: none
  &:active
    opacity: 0.9   
