.subscription-step
  position: relative
  padding: 20px 32px 40px
  margin-bottom: 16px

  &_disabled
    filter: grayscale(100%)
    opacity: 0.6

.subscription-step__screen
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1

.subscription-step__counter
  display: block
  $font-size-small()
  line-height: 1

.subscription-step__header
  $font-size-large()
  line-height: 1
  margin-bottom: 0
  font-weight: normal
  &+ .spacer_20
    margin-bottom: 0

.spacer_20 + .toggler
  margin-top: 20px

.spacer_20 + .subscription-form__radio-wrapper.payment-options
  margin-top: 20px

.gift-payment__inner
  .subscription-step__header
    margin: 10px 0 30px

.subscription-step__description
  $font-size-small()

.subscription-description-advantages
  list-style-type: none
  padding: 0

  .subscription-description-advantage
    $font-size-small()
    font-family: $font-graphik
    font-weight: 600
    display: flex
    align-items: end

    .SVGInline
      margin-right: 8px

.subscription-step-inner
  margin: 0 -32px -40px -32px
  padding: 32px
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px

  @media $bp.mobile
    margin: 0 -16px -40px -16px
    padding: 16px 16px 32px 16px

.subscription-step_mode-dark
  $card-dark()

  .subscription-step-inner
    color: white

  .stripe-card-element
    background-color: $base-primary-dark
    color: rgba(255, 255, 255, 0.6)
    border: 1px solid rgba(255, 255, 255, 0.12)

  .stripe-credit-card__cardholder-field .input
    background-color: $base-primary-dark
    color: white
    border: 1px solid rgba(255, 255, 255, 0.12)
    &::placeholder
      color: rgba(255, 255, 255, 0.6)

  .stripe-credit-card__cvc-info
    background-color: #4a4a4a

.subscription-step_mode-light
  $card-light()
  .subscription-step__counter
    color: $text-secondary

 .subscription-description-advantage
    color: $text-primary
