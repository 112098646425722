.slider-controls__pager
  position: relative
  display: flex
  justify-content: center
  margin: 0
  padding: 0

.slider-controls__pager-item-container
  list-style-type: none
  display: inline-block

  &:not(:last-child)
    margin-right: 14px

.slider-controls__pager-item
  display: inline-block
  width: 8px
  height: 8px
  border-radius: 50%
  background-color: $text-disabled
  cursor: pointer

.slider-controls__pager-item_active
  background-color: $text-primary
