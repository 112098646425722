.badge
  $font-size-xx-small()
  display: inline-flex
  align-items: center
  position: relative
  z-index: 2
  box-sizing: border-box
  min-height: 20px
  padding: 0 8px
  margin-right: 4px
  font-weight: bold
  border-radius: 12px
  text-transform: uppercase
  text-decoration: none
  white-space: nowrap

  html.no-flex-wrap &
    display: inline-block

.badge_disabled
  display: inline-flex

.badge_language
.badge_user_upload
.badge_litres
  color: $text-secondary
  border: 1px solid $base-line-secondary

.badge_list
  min-height: 15px
  margin: 0 8px 0 0
  padding: 2px 8px
