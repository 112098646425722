.toggler__input
  display: none

.toggler__default-option
  $font-size-xx-small()
  font-weight: bold
  box-sizing: border-box
  display: inline-block
  margin-right: 4px
  background: white
  color: $text-secondary
  border: 1px solid $base-line-secondary
  min-height: 40px
  line-height: @min-height
  min-width: 96px
  text-align: center
  text-transform: uppercase
  cursor: pointer
  border-radius: 4px
  transition: background-color 0.3s ease

  &_selected
    color: $text-primary
    background: lighten($base-line-accent, 80%)
    border: none
    cursor: default

.toggler__radio-option
  $font-size-xx-small()
  margin-right: 16px
  margin-bottom: 16px
  position: relative
  box-sizing: border-box
  border-radius: 4px
  display: inline-block
  color: $text-primary
  border: 1px solid $base-line-secondary
  background-color: white
  text-transform: uppercase
  padding: 8px 8px 8px 32px
  min-height: 100px

  &::before
    content: ''
    box-sizing: border-box
    position: absolute
    top: 8px
    left: @top
    width: 16px
    height: @width
    box-shadow: 0 0 0 1px $base-line-secondary
    border-radius: 50%

  &:hover
    cursor: pointer
    border-color: $base-line-accent
  &_selected
    &:hover
      cursor: default
    border-color: $base-line-accent

  &_selected
    &::before
      width: 6px
      height: @width
      top: 13px
      left: @top
      box-shadow: 0 0 0 6px $base-line-accent

.subscription-plans&.new-flow&.toggler__label
  &:hover
    cursor: pointer
  .toggler__radio-option
    width: 264px
    height: 130px
    &::before
      width: 22px
      height: @width
      top: 20px
      left: 20px
    &::after
      content: ""
      position: absolute
      display: none
      left: 28px
      top: 24px
      width: 5px
      height: 10px
      border-style: solid
      border-color: $text-accent
      border-image: initial
      border-width: 0px 2px 2px 0px
      transform: rotate(45deg)

  .toggler__radio-option_selected
    &::before
      box-shadow: 0 0 0 1px $text-accent
    &::after
      display: block
