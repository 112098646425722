.library-posts__image-background
.library-posts__darkened-background
  position: absolute
  height: 100%
  width: 100%
  top: 0
  left: 0

.library-posts__darkened-background
  background: rgba(#000, 0.15)

.library-posts__container
  width: 100%
  height: 340px

  @media $bp.mobile
    position: relative
    height: 385px

.library-posts__content-container
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  max-width: 960px
  margin: auto
  height: 100%

  @media $bp.mobile
    display: block
    padding-top: 24px
    width: 100%

.library-posts__post
  position: relative
  height: 340px

  @media $bp.mobile
    height: 385px
    width: 100%
    margin: 0

.library-posts__fake-link
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 3

.no-js .library-posts__post
  width: 100vw

.library-posts__book-cover-container
  z-index: 1

  .book-cover
    position: relative
    z-index: 1

  @media $bp.mobile
    display: flex
    justify-content: center
    margin: 0 auto 16px

.library-posts__text-container
  margin-left: 60px
  width: 400px
  max-height: 80%
  box-sizing: border-box
  white-space: pre-wrap

  @media $bp.tablet
    width: 350px

  @media $bp.mobile
    margin: 0
    width: 100%
    padding: 0 16px

.library-posts__book-title
  position: relative
  font-family: $font-kazimir
  $font-size-xx-large()
  color: white
  line-height: 1.18
  margin-bottom: 1rem
  z-index: 1

  @media $bp.mobile
    $font-size-large()
    text-align: center
    margin-bottom: 0.5rem

.library-posts__annotation
  position: relative
  z-index: 1
  lineHeight = 1.4
  font-family: $font-graphik
  $font-size-medium()
  color: white
  line-height: lineHeight
  max-height: (16 * 3 * lineHeight)px // font-size * line-height * number of lines
  overflow-y: hidden

  @media $bp.mobile
    $font-size-small()
    text-align: center
    max-height: (14 * 3 * lineHeight)px // font-size * line-height * number of lines

.library-posts__attribution-container
  display: flex
  position: relative
  align-items: center
  color: white
  margin-top: 30px
  z-index: 4

  @media $bp.mobile
    margin-top: 20px
    width: 100%
    justify-content: center
    $font-size-small()

.library-posts__attribution-container:hover
  .library-posts__attribution
    border-bottom: 1px solid white

.library-posts__attribution
  display: inline-block
  margin-left: 0.5rem

  @media $bp.mobile
    $font-size-x-small()

.library-posts__attribution-link
  color: white
  text-decoration: none

  &:hover
    color: white
