.auth-providers
  margin-top: 32px
  text-align: center

  &__wrapper
    margin-top: 14px

  .auth-providers__button
    fill: #fff
    margin-bottom: 15px

    &_more
      $font-size-small()
      min-height: 0
      padding: 9px 23px
      border-radius: 100px

      background-color: rgba($base-line-primary, 5%)
      color: $text-secondary
      font-weight: 300
      line-height: 1

      &:hover
      &:active
        background-color: rgba($base-line-primary, 15%) !important
        color: $text-secondary !important

.auth-providers__icon
  display: inline-block
  overflow: hidden
  vertical-align: middle
  width: 24px
  height: @width
  margin-right: 8px

  &-apple path
    fill: black


.auth-providers__link
  display: inline-block
  margin-right: 8px
  min-height: 24px
  line-height: @min-height
  cursor: pointer

.auth-providers_links
  $font-size-small()
  text-align: left

.auth-providers__title
  display: inline-block
  min-height: 24px
  line-height: @min-height
  margin-right: 16px

.button_mtn_ghana
  background: #FFCB05
  color: black

.button_yettel
  background: rgba(180, 255, 0, 1)
  color: rgba(0, 35, 64, 1)
