.subscription-form__auth
  max-width: 340px
  margin: 0 auto

.subscription-form__flex-wrapper
  display: flex
  align-items: center
  .user-avatar
    margin-right: 8px

.subscription-form__authenticated-name
  $font-size-x-large()
  font-weight: bold

.subscription-form__button-wrapper
  .plan-option
    min-width: 160px

    @media $bp.mobile
      min-width: 112px

.subscription-form__button-wrapper
  @media $bp.mobile
    margin-right: 8px

.trial-header
  &__wrapper
    display: flex
    max-width: 530px
    justify-content: space-between
    margin: auto
    align-items: flex-end

  &__description
    font-family: $font-graphik

  &__icon
    @media $bp.mobile
      margin-right: 11px

    svg
      width: 70px
      height: 65px

.subscription-form .recurrent-notice
  display: block
  font-family: $font-graphik
  $font-size-x-small()
  color: $text-secondary

.subscription-featured__wrapper .copyright,
.subscription-page__wrapper .copyright
  padding: 0
  color: $text-secondary
  $font-size-x-small()
  font-family: $font-graphik
  font-weight: normal

.subscription-step .auth__header
  $font-size-x-large()

.subscription__go-back
  font-family: $font-graphik
  $font-size-medium()
  color: $text-accent
  width: fit-content
  display: flex
  align-items: center
  &:hover
    cursor: pointer
    opacity: 0.8
  > .SVGInline
    height: 18px
    margin-right: 12px
    > svg
      fill: $text-accent

.subscription-form__button-wrapper&.new-flow
  .plan-option__duration
    $font-size-medium()
    text-transform: none
    margin: 4px 0 11px 18px

  .plan-option__price
    font-weight: bold
    font-family: inherit
    $font-size-large()
    text-transform: none
    margin-left: -12px

  .payment-caption
    display: block
    color: $text-secondary
    text-transform: none
    $font-size-small()
    margin-left: -12px

.subscription-selected-info
  width: 100%
  min-height: 250px
  border: 1px solid #e0dedb
  box-sizing: border-box
  border-radius: 8px
  padding: 24px
  display: flex
  justify-content: space-between
  flex-direction: column
  &__head
    display: flex
    justify-content: space-between
    align-items: center

    &>.product__icons 
      margin: 0
      
      &>.product-card__icon
        height: 24px

  &__product
    font-family: $font-graphik
    $font-size-large()
    line-height: 1.4
    &> h4
      font-weight: bold
      margin-block-end: 0
      margin-block-start: 0

.subscription-form
  .subscription-step__header
    font-weight: bold
    font-family: $font-graphik
    $font-size-large()
    margin: 10px 0
  .toggler
    display: flex
    flex-wrap: wrap

.code-subscription-layout__form 
  width: 100%

.code-subscription-layout__form .subscription-form__radio-wrapper.payment-options,
.special-offer-payment-form .subscription-form__radio-wrapper.payment-options
  > .toggler
    margin-top: 24px

.gift-payment .subscription-form__radio-wrapper.payment-options
  .toggler__radio-option
    color: white
    background: #383838
    box-shadow: 0 1px 0 rgba(0,0,0,.8)
    border: 1px solid white
    &:hover,
    &.toggler__radio-option_selected 
      border: 1px solid #5A9CFF

.code-subscription-layout__form .subscription-form__radio-wrapper.payment-options,
.special-offer-payment-form .subscription-form__radio-wrapper.payment-options,
.subscription-form .subscription-form__radio-wrapper.payment-options,
.gift-payment .subscription-form__radio-wrapper.payment-options
  > .toggler
    display: flex
    justify-content: flex-start
    width: 100%
    flex-wrap: nowrap
    @media screen and (max-width: 370px)
      flex-wrap: wrap

  .toggler__label
    &:not(:last-child)
      margin-right: 16px
    width: calc((100% - 32px) / 3)
    > .toggler__radio-option 
      > .subscription-form__button-wrapper
        margin: 0

.taxes-comment
  color: $text-secondary
  display: inline-block
  $font-size-x-small()

.croatia-caption
  color: $action-accent
  display: block
  font-family: $font-graphik
  $font-size-x-small()
  margin-top: 12px

.button_payment-intent
  $font-size-medium()
  text-transform: inherit

.purchase-error__card
  padding: 24px 20px 18px 24px
  margin: auto
  box-sizing: border-box
  max-width: 450px
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25)
  background: $promo-seafoam-blue
  border-radius: 8px
  text-align: center
  > button
    margin: auto
    display: block
  > .purchase-error__icon
    margin: auto
    display: block
    width: 24px
    height: @width
    >svg>g>path
      fill: $action-error

.pending-message
  max-width: 560px
  margin: 24px auto 0
  > button
    max-width: 250px
    margin: 24px auto 0
    display: flex
    align-items: center
    justify-content: center
  
  @media $bp.mobile
    max-width: calc(100% - 48px)
    text-align: center

  .status-reload__icon 
    width: 24px
    height: @width
    margin-right: 8px

  &:hover .status-reload__icon > svg > path
    stroke: $action-accent

.button_retry-purchasing
  margin-left: 8px!important
.subscription-step
  .button_secondary svg:not(.dropdown__item-icon-svg)
    width: 24px
    height: @width
.pending-message &.gifts
  color: white
  > .button
    color: white
    border: 1px solid white

.status-reload__icon &.gifts
  path
    stroke: white
