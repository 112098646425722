.meta-counters
  $font-size-x-small()
  display: flex
  min-height: 24px
  align-items: center
  line-height: 16px
  padding: 0
  margin: 0
  color $text-secondary
  list-style: none

  @media $bp.mobile
    $font-size-xx-small()
    line-height: 10px

.meta-counters__item
  display: flex
  align-items: center
  padding-right: 8px

  &:last-child
    padding-right: 0

  @media $bp.mobile
    padding-right: 4px

.meta-counters__icon
  display: block
  width: 20px
  height: @width

  svg
    fill: $text-secondary

  @media $bp.mobile
    width: 16px
    height: @width
