.credit-card-placeholder
  position:relative
  background-color: $base-secondary
  padding-bottom: 24px

.credit-card-placeholder__mask-layer
  position: relative
  font-size: 0

.credit-card-placeholder__mask-layer-top
  animation-duration: 1s
  animation-fill-mode: forwards
  animation-iteration-count: infinite
  animation-name: credit-card-placeholder-shimmer
  animation-timing-function: linear
  background: $base-disabled
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
  background-size: 1000px 104px

  @media $bp.mobile
    background-size: 780px 104px

.credit-card-placeholder__mask-layer-bottom
  background-color: $action-disabled

.credit-card-placeholder__mask
  position: relative
  fill: $base-secondary
  width: 100%
  font-size: 0

.credit-card-placeholder__error
  $font-size-small()
  font-weight: bold
  box-sizing: border-box
  padding: 32px
  background-color: #f5d8d4
  border: 1px solid #f79188
  margin-bottom: -24px

  @media $bp.mobile
    padding: 16px


@keyframes credit-card-placeholder-shimmer
  0%
    background-position: 110% 0
  100%
    background-position: -110% 0
