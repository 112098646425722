$header-padding = 16px

.header
  display: flex
  position: relative
  height: $header-height
  padding 0 $header-padding
  justify-content: center
  align-items: center
  box-sizing: border-box
  z-index: 900

  border-bottom: 1px solid rgba($base-line-secondary, 0.5)
  background-color: white
  user-select: none

  @media $bp.mobile
    padding: 0
    height: 58px

  .subscription-button_banner
    margin-top: 64px

  .subscription-button_small
    @media $bp.mobile
      display: none

  .search-header
    border-radius: 8px
    margin-right: 27px
    max-width: 350px

  .search-header__input
    min-width: 268px
    height: 44px
    background: #f7f7f7
    border: none

  .search-header__icon svg
    width: 40px
    height: @width
    right: auto
    left: 56px
    cursor: auto

.header__nav
  display: flex
  height: 44px
  width: 90%

  @media $bp.mobile

    html.no-flex-wrap &
      display: table
      text-align: center

  &_custom
    &-family
      width: 50%

.header__nav-link
  $font-size-small()
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  position: relative
  font-family: $font-graphik
  font-weight: bold
  text-decoration: none
  color: $text-primary
  cursor: pointer

  @media $bp.mobile
    html.no-flex-wrap &
      display: table-cell
      vertical-align: middle

    .user-avatar
      border: 2px solid white

  &_active
  &:hover
    color: $text-accent

    .header__icon-svg
      fill: $text-accent

    .header__nav-link__login svg
      fill: none
      circle
        stroke: $text-accent
      path
        stroke: $text-accent

    .user-avatar
      @media $bp.mobile
        border-color: $text-accent

  &_center:not(:last-child)
    margin-right: 30px

    @media $bp.tablet
      margin-right: 16px

  &_right:not(:last-child)
    margin-right: 20px

    @media $bp.tablet
      margin-right: 12px

.header__nav-col
  max-width: 890px
  min-width: fit-content
  display: flex
  height: 100%
  align-items: center

  @media $bp.tablet
    $column(5)

.header-nav__links
  min-width: fit-content
  display: flex
  align-items: center
  @media $bp.tablet
    width: 100%
    margin: 10px 0 0 5px

.header__controls
  position: absolute
  top: 50%
  transform: translateY(-50%)
  display: flex
  justify-content: space-between
  align-items: center

  @media $bp.mobile
    height: 100%
    top: 30%

.header__controls_left
  left: $header-padding

  @media $bp.mobile
    left: 8px

.header__controls_right
  right: $header-padding

  @media $bp.mobile
    right: 8px

.header__icons
  display: flex
  height: 100%
  margin-right: 12px
  align-items: center

  @media $bp.mobile
    margin-right: 5px

.header__icon
  display: inline-block
  width: 32px
  height: @width
  margin: 0 2px
  vertical-align: middle
  line-height: 1
  fill: $text-primary

  @media $bp.mobile
    width: 40px
    height: @width
    margin: 0 4px
    font-size: 0

    &_menu
      width: 18px
      margin: 10px 10px 0 12px
      svg
        width: 18px
        height: 18px
        margin: 8px 0

.header__icon-svg
  overflow: hidden

.header__icon_notifications
  position: relative
  cursor: pointer

.header__icon_notifications_unread::before
  content: ''
  background: $action-error
  position: absolute
  right: 6px
  top: 7px
  width: 8px
  height: 8px
  border-radius: 50%

.header__subscription-link
  $btn-primary()
  $font-size-x-small()
  border-radius: 78px
  padding: 4px 16px
  min-height: 0

.header__nav-link__login svg
  margin: 8px

.header__avatar
  position: relative
  cursor: pointer

.header_fixed
  position: fixed
  width: 100%

.header_narrow
  @media $bp.mobile
    height: 58px

  .header__controls
    @media $bp.mobile
      top: 50%

.header__nav-tab
  $font-size-small()
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  position: relative
  font-family: $font-graphik
  font-weight: bold
  text-decoration: none
  color: $text-primary
  cursor: pointer
  border-bottom: 2px solid transparent
  padding: 22px 3px
  @media $bp.tablet
    padding: 16px 3px

  &_active
  &:hover
    border-color: $text-accent
    color: $text-accent

  &_center:not(:last-child)
    margin-right: 30px

    @media $bp.tablet, $bp.desktop
      margin-right: 12px

    @media screen and (min-width: 1024px) and (max-width: 1145px)
      margin-right: 10px

  &_right:not(:last-child)
    margin-right: 20px

    @media $bp.tablet
      margin-right: 12px

.more-symbol
  font-size: 8px
  margin: 2px 0 0 3px

.header__nav-tab
  &.linka_pseudo:hover
    border-color: transparent
  .dropdown
    width: 150px
    .header__nav-tab
      margin: 0 0 0 16px
      width: fit-content
      border-bottom: none

.margined
  margin-left: -8px

.header-icon_bookmate-run
  &_33
    margin-right: 8px
    height: 33px
    > img 
      height: 33px
  &_40
    height: 40px
    > img 
      height: 40px
