.store-button
  display: flex
  align-items: center
  text-align: left
  text-decoration: none
  border-bottom: none
  border: 1px solid
  transition: all 0.3s

  .store-button__icon > svg
    width: 100%
    height: 100%
    transition: all 0.3s

  &_big
    padding: 8px 16px 8px 8px
    $font-size-medium()
    line-height: 1
    border-radius: 6px

    .store-button__icon
      width: 40px
      height: 40px
      margin-right: 8px

    .store-button__text
      font-weight: bold

      small
        font-size: 0.75em
        font-weight: normal

  &_small
    padding: 4px 8px 4px 4px
    $font-size-x-small()
    line-height: 1
    border-radius: 4px

    .store-button__icon
      width: 24px
      height: 24px
      margin-right: 4px

    .store-button__text
      font-weight: bold

      small
        font-size: 0.6em
        font-weight: normal

  &_light
  &_light2
    color: $text-primary

    .store-button__icon > svg
      fill: $text-primary

    &:hover
      color: $text-accent
      border-color: $text-accent

      .store-button__icon > svg
        fill: $text-accent
        transition: all 0.3s

  &_light
    border-color: $base-line-secondary

  &_light2
    border-color: $text-primary

  &_dark
    color: white
    background-color: black
    border-color: black

    .store-button__icon > svg
      fill: white

    &:hover
      color: white
      background-color: lighten(black, 12%)
      border-color: black
