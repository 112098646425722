.languages-button
  display: flex
  justify-content: flex-start
  align-items: center
  width: 32px
  height: 32px

  $font-size-x-small()
  font-weight: bold
  text-transform: uppercase
  text-decoration: none
  color: black
  cursor: pointer

  &:hover
    color: $text-accent

.languages::-webkit-scrollbar
  -webkit-appearance: none
  width: 7px

.languages::-webkit-scrollbar-thumb
  border-radius: 4px
  background-color: rgba(0, 0, 0, 0.17)
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)

.languages
  width: 184px
  padding: 10px 24px
  max-height: 298px
  overflow-y: scroll

  @media $bp.mobile
    width: 90%
    max-height: 478px

  &__item
    $font-size-small()
    display: flex
    justify-content: space-between
    align-items: center
    height: 40px
    font-weight: 500
    text-transform: capitalize

    &:hover
      cursor: pointer
      color: $text-accent

    &-icon
      display: none
      height: 24px
      width: @height
      margin-left: 8px

      path
        fill: $text-accent

    &_active
      color: $text-accent

      &:hover
        cursor: default

      .languages__item-icon
        display: inline-block
