.stripe-credit-card
  text-align: left

.stripe-credit-card__loader
  position: absolute
  top: 50%
  left: 50%
  margin-top: -36px
  margin-left: -24px
  z-index: 1

.stripe-credit-card__inner
  width: 100%
  position: relative

  @media $bp.mobile
    box-sizing: border-box

.stripe-credit-card__inner_loading .stripe-credit-card__form
  opacity: 0.2

.stripe-credit-card__row
  display: flex
  justify-content: space-between
  align-items: baseline

  @media $bp.mobile
    display: block

.stripe-credit-card__input-group
  width: 100%
  position: relative
  font-size: 0

.stripe-credit-card__field-label
  $font-size-small()
  font-weight: bold
  display: inline-block
  line-height: 1
  margin-bottom: 12px

.stripe-credit-card__cvc-label
  margin-right: 6px

.stripe-credit-card__field-label-comment
  $font-size-xx-small()
  margin-top: -3px
  margin-bottom: 6px

.stripe-credit-card__field-label-dropdown
  width: 170px
  padding: 0 20px
  white-space: pre-wrap

.stripe-credit-card__input-group_short
  display: inline-block
  vertical-align: top
  width: 112px
  margin-right: 24px

  @media $bp.mobile
    width: 100%
    margin-right: 0

    &:first-child
      margin-bottom: 16px

  &:last-child
    margin-left: auto
    margin-right: 0

.stripe-credit-card__cardholder-field
  width: 288px

  @media $bp.mobile
    width: 100%

  // should be consistent with styles passed to Stripe elements when they are created
  .input
    height: 52px
    font-family: Helvetica, sans-serif
    text-transform: uppercase
    border 1px solid $text-disabled

    &:focus
      border-color: $text-accent

    &::placeholder
      color: $text-disabled

.stripe-credit-card__field-error
  $font-size-xx-small()
  font-weight: bold
  color: $text-error
  height: 10px
  margin: 4px 0

.stripe-credit-card__payment-logo
  position: absolute
  top: 35px
  right: 5px
  width: 40px

.stripe-credit-card__cvc-info
  diameter = 20px
  $font-size-x-small()
  line-height: diameter
  position: relative
  display: inline-block
  width: diameter
  height: diameter
  border-radius: 50%
  background-color: $base-disabled
  color: $text-secondary
  text-align: center
  cursor: pointer

.stripe-credit-card
  &__recurrent-notice
    $font-size-small()

  &__notice-addition
    $font-size-small()
    color: #9a938d
    margin: 15px calc(10% - 15px) 0
    
    @media $bp.mobile
      margin: 20px 0

.stripe-credit-card__payment-rejected-error
  $font-size-small()
  line-height: 16px
  box-sizing: border-box
  color: #F74E45

.stripe-credit-card__submit-button-container
  width: 100%

  @media $bp.mobile
    width: 100%

.stripe-credit-card__error
  $font-size-x-small()
  color: $text-error
  padding-top: 16px

.stripe-credit-card__privacy-notice
  display: flex

.stripe-credit-card__privacy-notice
  &__wrapper
    display: flex
    align-items: center
    $font-size-x-small()
    color: $text-secondary

    @media $bp.mobile
      flex-wrap: wrap

    a
      color: $text-secondary
    a:hover
      color: $text-accent

    @media $bp.mobile
      max-width: 100%

  &__text
    display: inline-block
    max-width: 360px
    margin-left: 12px

    @media $bp.mobile
      max-width: calc(100% - 40px)

  &__icon
    display: inline-block
    width: 28px
    height: 28px
    background-color: #fafaf8
    border-radius: 15px

    svg
      width: 28px
      height: 28px

.subscribe-form__accepted-cards
  display: flex
  justify-content: space-between

  .visa-icon
    margin-right: 8px

.error_all
  .cvc,
  .card-number
  .expiration-date
  .card-holder
    .stripe-card-element
      border: 1px solid $action-error!important

.error_cvc .cvc .stripe-card-element
  border: 1px solid $action-error

.error_card-number .card-number .stripe-card-element
  border: 1px solid $action-error

.error_expiration-date .expiration-date .stripe-card-element
  border: 1px solid $action-error  
