.stripe-credit-card__payment-rejected-error
  display: flex

.stripe-error_icon
  margin-right: 9px
  > svg 
    height: 16px
    width: @height
    > g
      fill: $action-error
