.series
  $list-view()
  position: relative
  align-items: stretch
  padding: 14px 20px 16px 20px
  white-space: pre-wrap
  height: 200px

  @media $bp.mobile
    padding-left: 16px
    padding-right: 16px

  &_grid
    display: inline-flex

.series__description
  display: flex
  flex-direction: column
  flex: 0 1 auto
  flex-grow: 2
  min-width: 0
  margin-right: 16px

  @media $bp.mobile
    padding-right: 8px

.series__authors
  margin-bottom: 8px
  color: $text-secondary
  z-index: 3

  a
    color: $text-secondary

.series__title
  $list-view-title-link()
  color: $text-primary
  z-index: 2

.series__labels-wrapper
  display: flex
  margin-top: auto
  flex-direction: row
  align-items: center

.series__new-episode-label
  display: inline;
  padding-left: 10px
  text-transform: uppercase
  width: auto
  font-size: 10px
  line-height: normal
  color: $action-error

.series__new-episode-label:before
  content: ''
  position: absolute
  left: 13px
  width: 9px
  height: 9px
  border-radius: 50%
  border: 1px solid $action-error
  background-color: $action-error

$card-sizes = 88 288 350 408

for size in $card-sizes
  .series_{size}
    width: (size)px
