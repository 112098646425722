.stripe-card-element
  background: white
  border 1px solid $base-line-secondary
  border-radius: 4px
  padding: 5px 20px

  &_focused
    border-color: $base-line-accent

  &_error
    border-color: #fca59e
