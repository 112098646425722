.slider
  width: 100%
  height: 340px

  @media $bp.mobile
    height: 385px

.slider__btn
  position: absolute
  top: 50%
  margin-top: -40px
  opacity: 0.3
  z-index: 10

  &:hover
    opacity: 0.8
  &_prev
    left: 30px
  &_next
    right: 30px

  @media $bp.mobile
    display: none

.slider__wrapper
  position: absolute
  left: 0
  width: 100%
  height: 340px
  overflow: hidden

  @media $bp.mobile
    height: 385px

.slider__frame
  position: relative
  height: 100%
  overflow: hidden
  width: 100%

.slider__frame, .slider__track
  &:focus
    outline: none

.slider__mobile-wrapper > .horizontal-scroller > *
  display: inline-block
  vertical-align: top
  border-radius: 8px
  width: 88%
  margin: 0 12px
  overflow: hidden

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  @media $bp.oneColumn
    &:first-child
      margin-left: 8px

    &:last-child
      margin-right: 8px

  @media $bp.mobile
    width: 90%
    margin: 0 4px
