.arrow-button
  padding: 0
  border: none
  cursor: pointer
  width: 40px
  height: @width
  transition: opacity 0.3s
  background-color: alpha($text-primary, 20%)
  border-radius: 2px

  & svg
    fill: white
    width: 100%
    height: 100%

  &:focus
    outline: none

  &_small
    width: 24px
    height: @width

  &_medium
    width: 64px
    height: @width

  &_big
    width: 80px
    height: @width

  &_transparent
    border-radius: 8px
    background-color: $scroller-controls-hover
    height: 100%

    &:active
      background-color: $scroller-controls-active

    & svg
      fill: $action-scroll

  &_round
    border-radius: 50%
    background-color: white
    margin: auto
    display: block

    & svg
      width: 50%
      margin: auto
      display: block
      height: 100%

.arrow-button__next svg
  transform: rotate(180deg)
