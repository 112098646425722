.login-form__error
  text-align: center
  color: $text-error

.login-form__social
  margin-top: 40px

.login-form__link
  $font-size-small()
  display: inline-block
  font-weight: bold
  text-decoration: underline
  margin-top: 24px
  cursor: pointer

.login-form__subaction
  $font-size-small()
  display: block
  line-height: 1.3
  text-decoration: underline
  color: $text-secondary
  cursor: pointer
  margin: 0 0 16px
