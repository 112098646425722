$checkboxWidth = 24px

.checkbox

  label
    display: flex
    align-items: center
    cursor: pointer

  input
    display: none

  .checkbox__box, .checkbox__label
    box-sizing: border-box
    color: $text-secondary

  .checkbox__box
    width: $checkboxWidth
    height: @width
    flex: 0 0 $checkboxWidth
    border-radius: 4px
    border: 1px solid $base-line-secondary
    background: white
    cursor: pointer
    margin-right: 15px
    user-select: none
    margin-top: 0.5em
    border: 1px solid $base-line-secondary
    border-radius: 4px

  .checkbox__checkmark
    display: flex
    width: 100%
    height: 100%
    justify-content: center
    align-items: center

    .SVGInline, svg
      width: 20px
      height: 20px

    svg
      fill: white

  input + label .checkbox__checkmark
    visibility: hidden

  input:checked + label .checkbox__box
    background: $text-primary

  input:checked + label .checkbox__checkmark
    visibility: visible

  input:checked + label .checkbox__label
    color: $text-primary
