.minimal-header
  position: absolute
  width: 100%
  top: 0
  left: 0
  z-index: 5

  @supports (position: sticky)
    position: sticky

  @media $bp.mobile
    flex-wrap: wrap
    margin-top: 0

    @supports (position: sticky)
      position: relative
      margin-top: auto
      height: 30px

.minimal-header__logo
  position: absolute
  top: 48px
  left: 64px

  @supports (position: sticky)
    top: 32px

  @media $bp.tablet
    left: 32px

  @media $bp.mobile
    top: 8px
    left: 8px

.minimal-header__back-button
  position: absolute
  display: inline-flex
  top: 48px
  right: 64px

  &_white svg,
  &_blue svg
    fill: white
  &_brownish svg
    fill: $base-primary-dark

  @supports (position: sticky)
    top: 32px

  @media $bp.tablet
    right: 32px

  @media $bp.mobile
    top: 8px
    right: 8px

.seafoam-bg
  background-color: $promo-seafoam-blue
