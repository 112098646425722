.fallback-cover
  width: 100%
  height: 100%
  box-sizing: border-box

  text-align: center
  line-height: 1.2
  white-space: pre-wrap

  background-color: #fde3a7
  border: $light-tan solid 2px
  outline: rgba(216, 211, 208, 0.1) solid 1px
  outline-offset: -1px
  color: $text-primary

.fallback-cover-title
  font-family: $font-kazimir
  word-wrap: break-word
  word-break: break-word
  hyphens: auto
  text-transform: uppercase

.fallback-cover-separator
  line-height: 0.2

.fallback-cover_56
  padding: 4px 2px
  border-width: 3px

  .fallback-cover-title
  .fallback-cover-separator
    font-size: 7px

  .fallback-cover-separator
    padding: 4px 0

  .fallback-cover-authors
    font-size: 4px

.fallback-cover_88
  padding: 5px
  border-width: 4px

  .fallback-cover-title
  .fallback-cover-separator
    font-size: 9px

  .fallback-cover-separator
    padding: 5px 0

  .fallback-cover-authors
    font-size: 5px

.fallback-cover_120
  padding: 8px 5px
  border-width: 6px

  .fallback-cover-title
  .fallback-cover-separator
    font-size: 14px

  .fallback-cover-separator
    padding: 8px 0

  .fallback-cover-authors
    font-size: 8px

.fallback-cover_176
  padding: 13px 5px
  outline-width: 2px
  outline-offset: -2px
  border-width: 8px

  .fallback-cover-title
  .fallback-cover-separator
    font-size: 20px

  .fallback-cover-separator
    padding: 13px 0

  .fallback-cover-authors
    font-size: 11px

.fallback-cover_208
  padding: 16px 5px
  outline-width: 2px
  outline-offset: -2px
  border-width: 10px

  .fallback-cover-title
  .fallback-cover-separator
    font-size: 24px

  .fallback-cover-separator
    padding: 16px 0

  .fallback-cover-authors
    font-size: 13px
