.social-links__footer
  display: block
  margin-bottom: 16px

.social-link__icon
  display: inline-block
  vertical-align: middle
  cursor: pointer
  position: relative
  margin-top: -4px
  margin-right: 24px
  width: 32px
  height: @width

.social-link__icon-svg
  fill: $text-secondary

  &:hover
    fill: darken($text-secondary, 20%)
