.input
  $font-size-medium()
  font-family: $font-graphik
  display: block
  box-sizing: border-box
  width: 100%
  border-radius: 4px
  color: $text-primary
  border: 1px solid $base-line-secondary
  margin: 0 0 16px
  padding: 15px 20px
  transition: border-color 0.3s ease

  &__wrapper
    position: relative

  &_clear
    stroke: none
    fill: rgba(0, 0, 0, 0.2)
    height: 16px
    width: @height
    position: absolute
    top: 20px
    right: @top

  &:focus
    outline: none

  &::placeholder
    color: $text-secondary

  &_error
    border-color: $action-error

  &_code
    $font-size-large()
    letter-spacing: 0.5em
    padding: 11px 20px
    text-align: center

  &_with-userpic
    padding-right: 56px

  &_big
    padding-top: 19px
    padding-bottom: @padding-top

  &_icon
    background-repeat: no-repeat
    background-position: 12px 50%
    background-size: 12px 12px
    padding-left: 30px
