.code-success-layout
  background-color: $promo-seafoam-blue

  .layout-column
    flex-direction: column; 

  &__image
    width: 282px
    height: 275px
    margin: auto
    background: url('./subscribed.png') no-repeat center / contain

  &__description
    text-align: center

  &__link
    $link-underline()
    color: $action-accent
    border-bottom-color: $action-accent

  &__qr
    width: 130px
    height: @width
    margin: auto
    background: url('https://a.bmstatic.com/iu/250/63/QR-a1-b36afc47f05b72d369d4480dd7586ae6.jpeg') no-repeat center / contain

  &__buttons
    display: flex
    justify-content: center

.code-success__wrapper
  background-color: $promo-seafoam-blue
  min-height: 100vh
  .footer
    background-color: $promo-seafoam-blue

.code-success__qr-code 
  width: 150px
  margin: auto
  height: @width
  @media $bp.mobile
    display: none

