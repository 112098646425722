for size in $walker-cover-heights
  .walker_{size}
    height: (size)px

.walker
  position: relative
  cursor: pointer

  &__btn
    $reset-block-margins()
    position: absolute
    top: 0

    &_next
      right: -110px
      padding-left: 10px

    &_prev
      left: -110px
      padding-right: 10px

  &__link
    display: inline-block

    &-container
      $center-element()

    &-button
      $center-element()

    &-text
      $reset-block-margins()
      $font-size-xx-small()
      font-weight: bold
      text-align center
      margin-top: 6px
      text-transform: uppercase


  &__decoration
    height: 100%
    width: 100px

    &_next
      $center-element(start)

    &_prev
      $center-element(end)

@media screen and (max-width: 1515px)
  .walker
    &__decoration
      width: 65px

    &__btn
      &_next
        right: -75px

      &_prev
        left: -75px

@media screen and (max-width: 1430px)
  .walker
    &__decoration
      width: 40px

    &__btn
      &_next
        right: -50px

      &_prev
        left: -50px

@media screen and (max-width: 1295px)
  .walker
    &__decoration
      width: 65px

    &__btn
      &_next
        right: -75px

      &_prev
        left: -75px


@media screen and (max-width: 1180px)
  .walker__btn,
  .walker__link
    display: none

