.code-subscription-layout
  background-color: $promo-seafoam-blue

  &__cards
    display: flex
    justify-content: space-between

  &__card
    display: flex
    flex-direction: column
    width: 276px
    border-radius: 2px
    padding: 25px 12px
    background-color: #fff
    box-shadow: 0 1px 0 0 $base-line-secondary

    .carousel__frame &
      margin: auto
      margin-bottom: 10px

    &-top-wrap
      flex: 1 0 auto

    &-image
      width: 179px
      height: 171px
      margin: auto

      &_default
        background: url('./sub_default.png') no-repeat center / contain

      &_premium
        background: url('./sub_premium.png') no-repeat center / contain

    &-description
      margin: auto
      text-align: center

    &-button
      width: 220px
      margin: auto

    &-note
      $font-size-small()
      height: 38px
      margin-bottom: 0
      text-align: center
      color: $text-secondary

  &__form
    h3, div, button, input
      font-family: $font-graphik

    &-font-medium
      $font-size-medium()

    @media $bp.mobile
      width: 100%

.bookmate-arriva-logo
  height: 33px
  margin: auto
  display: block

.bookmate-ma60-logo
  margin: auto
  display: block

.bookmate-offer-desc
  text-align: center
  max-width: 580px
  margin: auto

.decor__books-train
  width: 65%
  margin: 0 auto 54px
  display: block
  padding-top: 35px
  @media $bp.mobile
    width: 100%
    margin: 0
    padding: 0

.decor-train-2_mobile
  width: 100%

.offer-caption
  text-align: center
