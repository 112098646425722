.header-dropdown
  width: 230px
  padding: 10px 24px 0
  box-sizing: border-box

  .footer
    line-height: 1.6

.header-dropdown__item
  $font-graphik
  $font-size-x-small()
  font-weight: bold
  line-height: 2.67

  .unread-alert::after
    content: ''
    background: #fa4c3e
    margin: 12px 40px
    float: right
    width: 8px
    height: 8px
    border-radius: 50%

.header-dropdown__item-link
  color: $text-primary
  text-decoration: none
  cursor: pointer

  &:hover, &:active
    color: $text-accent

.header-dropdown__separator
  height: 0px
  border: 0
  border-top: 1px solid $base-line-secondary
  margin-top: 12px
