for size in $audiobook-cover-sizes
  .audiobook-cover_{size}
    width: (size)px
    height: @width

.audiobook-cover
  position: relative
  overflow: hidden
  background: $base-overlay
  user-select: none

.audiobook-cover__play-icon
  $play-icon()

.audiobook-cover_unavailable
  .progressive-cover
    opacity: 0.6

.audiobook__unavailable
  &_wrapper
    position: absolute
    bottom: 8px
    z-index: 2
    width: 100%
  &_text
    white-space: normal
    text-align: center
    display: block
    width: fit-content
    width: -moz-fit-content
    max-width: 100px
    margin: auto
    text-transform: uppercase
    $font-size-xx-small()
    font-weight: 500
    color: white
    padding: 4px
    background: black
    opacity: 0.6
    border-radius: 100px
