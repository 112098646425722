$shelf-cover-sizes = 88 288 300 408

for size in $shelf-cover-sizes
  .shelf_{size}
    width: (size)px

.shelf__title
.shelf__creator
  $font-size-small()
  font-weight: normal
  line-height: 20px
  margin: 0

.shelf__title
  $list-view-title-link()
  text-decoration: none
  color: $text-primary

.shelf__title_without-spaces
  word-break: break-all

.shelf__creator
  color: $text-secondary

.shelf__cover
  flex-grow: 0
  flex-shrink: 0
  align-self: flex-start
  background-color: darken($text-secondary, 70%)

.shelf__wrapper
  display: flex
  align-items: center
  position: relative
  width: 100%

.shelf__footer
  display: flex
  height: 56px
  align-items: center
  padding: 0 16px

.shelf__user
  &avatar
  &name
    cursor: pointer

.shelf__useravatar
  display: block

.shelf__username
  max-width: 140px
  max-height: 60%
  $font-size-x-small()
  display: inline-block
  text-decoration: none
  padding-left: 1em
  color: $text-secondary
  overflow: hidden
  text-overflow: ellipsis

  @media $bp.tablet
    max-width: 80px
    white-space: nowrap

.shelf__meta-counters
  margin-left: auto

.shelf_list
  $list-view()

  .shelf__cover
    align-self: center

  .shelf__description
    $list-view-info()

  .shelf__title
    $list-view-title()

  .shelf__cover
    border-radius: 16px
    overflow: hidden
    -webkit-mask-image: -webkit-radial-gradient(white, black)

.shelf__button-footer
  padding: 26px 0
  text-align: center
  background-color: white
  box-shadow: 0 1px 2px 0 $base-line-secondary

.shelf_grid
  display: inline-block
  padding: 0
  box-shadow: 0 1px 0 0 $base-line-secondary
  overflow: hidden

.shelf_card
.shelf_grid
  border-radius: 4px
  background-color: white

  .shelf__title
    $font-size-large()
    padding: 0 16px
    font-family: $font-kazimir
    line-height: 1.4
    color: white
    text-align: center
    white-space: normal

  .shelf__cover
    width: 100%
    height: auto
    flex-basis: auto
    border-top-left-radius: 4px
    border-top-right-radius: 4px

    &::after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      top: 0
      background-color: $base-overlay

    @media $bp.mobile
      min-height: 160px

  .shelf__cover-wrapper
    height: 100%

  .shelf__description
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0

.shelf_card
  padding-bottom: 24px

  .shelf__title
    $font-size-xx-large()
    padding: 0 32px

    @media $bp.mobile
      $font-size-large()
      padding: 0 16px
