.back-button
  display: flex
  align-items: center
  position: relative
  height: 32px

.back-button__button
  display: block
  margin-left: 24px
  cursor: pointer
  width: 20px
  height: @width
  fill: $text-primary

.back-button_without-avatar
  float: right
  right: -64px

  @media $bp.oneColumn
    right: 0
