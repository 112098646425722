.browser-error__item
  display: inline-block
  vertical-align: top
  margin-right: 40px
  text-align: center

.browser-error__image
  width: 72px
  height: 72px
  margin-bottom: 8px
  background-repeat: no-repeat
  background-position: top center
  background-size: contain

  &_chrome
    background-image: url("./chrome.png")

  &_safari
    background-image: url("./safari.png")

  &_firefox
    background-image: url("./ff.png")

  &_edge
    background-image: url("./edge.png")

.browser-error__link
  color: $text-accent
