.heading
  margin: 0
  text-decoration: none
  font-family: $font-graphik
  font-weight: bold

.heading a
  $link-underline()

.heading_centered
  text-align: center

.heading_kazimir
  font-family: $font-kazimir
  font-weight: normal

.heading_graphik
  font-family: $font-graphik

.heading_1
  $font-size-x-large()

  &.heading_kazimir
    $font-size-xx-large()

  &.heading_graphik
    $font-size-xx-large()

.heading_2
  $font-size-large()

  &.heading_kazimir
    $font-size-x-large()

  &.heading_graphik
    $font-size-x-large()

.heading_3
  $font-size-large()

  &.heading_kazimir
    $font-size-large()

  &.heading_graphik
    $font-size-large()

.heading_4
  $font-size-medium()

  &.heading_kazimir
    $font-size-medium()

  &.heading_graphik
    $font-size-medium()

.heading_1.heading_extra_large
  $font-size-xxx-large()
  line-height: 54px
  position: relative
  z-index: 1
