.footer__links
  margin: 0
  padding: 0
  list-style: none
  color: $text-secondary

.footer__links_block
  display: block

.footer__links_inline
  display: inline-block
  margin-bottom: 16px
  color: $text-secondary

.footer__links_header-dropdown
  display: inline-block
  margin-bottom: 16px
  color: $text-secondary
  font-size: 0

  .footer__link
    font-size: 10px
    margin-right: 0.8em

.footer__links-item
  display: inherit

.footer__link
  $link-underline-on-hover()
  $font-size-x-small()
  margin-right: 1em
  color: inherit
