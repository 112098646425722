.book-status
  $font-size-xx-small()
  color: $text-secondary
  text-align: center
  font-weight: bold

  &_progress
  &_finished
  &_private
    display: inline-block
    line-height: 20px

    span
      display: inline-block

    svg
      display: inline-block
      overflow: hidden
      width: 20px
      height: @width
      vertical-align: middle
      fill: $text-secondary

  &_episode
    background: $action-disabled;
    border-radius: 3px;
    width: 20px;
    margin-right: 5px
