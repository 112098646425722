.list-header
  display: inline-block
  margin: 0
  font-weight: bold

  &__pseudolink
    display: flex
    align-items: center
    cursor: pointer

    &:hover
    &:active
      color: $text-accent

.list-header .heading_1
  line-height: 36px

.list-header .list-header__link
  display: flex
  align-items: center
  border: none

.list-header__link:hover

  .list-header__text
  .list-header__text-count
    color: $text-accent

  .list-header__button
    color: $text-accent

.list-header__text-count
  margin-left: 4px
  color: $text-secondary

.list-header__button
  $font-size-xx-small()
  font-family: $font-graphik
  color: $text-secondary
  display: inline-block
  max-height: 24px
  line-height: 24px
  margin-left: 8px
  padding: 0 8px
  text-transform: uppercase
  background: white
  border-radius: 100px
