.header-white
  width: fit-content

  .logos_header
    margin: 0 8px 0 0
    width: 100px

.controls_left
  @media $bp.mobile
    width: 100%

.header-black
  background: #030303
  width: 100%

  .logos_header
    margin: 0 32px 0 36px
    width: 290px

.salinas-header
  height: 64px
  display: flex
  align-items: center
  justify-content: start
  padding: 0
  @media $bp.mobile
    padding-left: 8px

  .logos_header
    @media $bp.mobile
      margin: 0 0 0 12px

  .header__controls
    @media $bp.mobile
      margin-top: 12px

  .header-icon_bookmate-run_33
    margin-right: 8px

  .login-button
    color: white!important

  .header-navigation_item
    color: white
    &:hover
      color: #9D81CC
      transition: color 0.2s
    &:not(:last-child)
      margin-right: 35px

  .header__nav-link 
    .header__icon-svg
      fill: white
      > rect
        fill: white

.salinas-body
  min-height: 100vh
  color: white
  @media $bp.desktopMedium, $bp.desktopLarge
    background: url('./left-large.png') no-repeat left / contain, url('./right.png') no-repeat right top / 25%, #030303
  @media $bp.desktop
    background: url('./left-medium.png') no-repeat -35px / contain, url('./right.png') no-repeat right 560px / 30%, #030303 
  @media screen and (min-width: 1023px) and (max-width: 1080px), $bp.tablet
    background: url('./left-small.png') no-repeat left / contain, url('./right.png') no-repeat right 560px / 35%, #030303 
  @media screen and (max-width: 770px), $bp.tablet
    background: url('./left-small.png') no-repeat -30px / contain, url('./right.png') no-repeat right 560px / 35%, #030303 
  @media screen and (max-width: 769px)
    background: url('./left-small.png') no-repeat -70px / contain, url('./right.png') no-repeat right 560px / 35%, #030303 
  @media $bp.mobile
    background: url('./mobile-body.png') no-repeat top / contain, #030303

  .checkbox__box
    margin-top: 0!important

  .checkbox__label > a
    color: white
    &:hover
      color: $text-accent

  .checkbox input:checked+label .checkbox__label
    color: #e7e7e7

.spaceholder
  height: 68px

.spaceholder_big
  height: 150px

.spaceholder_bottom_page
  height: 172px

.salinas-column
  margin: 0 auto
  max-width: 490px
  text-align: center
  @media $bp.mobile
    margin-top: 280px
    max-width: calc(100% - 48px)

  .heading_1
    font-size: 44px
    line-height: 140%

.salinas-description
  font-size: 18px
  line-height: 30px
  @media $bp.tablet
    max-width: 350px
    margin: auto

.salinas-register_form
  max-width: 338px
  margin: auto

.salinas-register_field
  display: block
  margin-bottom: 21px
  position: relative

.register-hint
  display: block
  text-align: left
  font-weight: bold
  font-size: 14px
  line-height: 17px

.register-input
  width: 100%
  background: white
  border: 1px solid #E7E7E7
  border-radius: 4px
  padding: 20px 18px
  height: 56px
  box-sizing: border-box
  &::placeholder
    text-align: center
    font-size: 16px
    line-height: 19px
    text-transform: uppercase
    color: #D8D4D0
    text-align: left

.invalid,
.valid
  position: absolute
  right: 5px
  top: 42px

.invalid
  color: $action-error

.valid
  color: $action-success

.logos_intro,
.logos_footer
  display: block
  margin: auto

.logos_intro
  width: 150px

.logos_footer
  width: 251px

.validation-body
  color: white
  min-height: 100vh
  @media $bp.desktopMedium, $bp.desktopLarge
    background: url('./top-large.png') no-repeat left top / 20%, url('./bottom-large.png') no-repeat right bottom / 20%, #030303
  @media $bp.desktop, $bp.tablet
    background: url('./top-medium.png') no-repeat left top / 20%, url('./bottom-medium.png') no-repeat right bottom / 20%, #030303
  @media $bp.mobile
    background: url('./mobile-validate.png') no-repeat right top / contain, #030303
  
  .salinas-column
    @media $bp.mobile
      margin-top: 200px

.store-buttons_group
  display: flex
  justify-content: space-between
  max-width: 361px
  margin: auto

  >.store-button
    max-width: 168px
    border: 1px solid #D8D4D0

.salinas-register_button
  max-width: 338px
  margin: auto

.highlighted
  color: #9D81CC

.link_return
  font-weight: bold
  font-size: 16px
  line-height: 19px
  text-align: center
  color: rgba(255, 255, 255, 0.5)
  text-decoration: underline

.salinas-page
  background-color: #030303
  min-height: 100vh

  > .loader
    padding: 48px

.salinas-banner
  width: 100%
  height: 334px
  background: url('./banner.png') no-repeat center / cover
  color: white
  overflow: hidden
  box-sizing: border-box
  @media $bp.desktopMedium, $bp.desktopLarge
    padding: 5% 0 5% 15%
  @media $bp.desktop
    padding: 4% 5% 5% 10%
  @media $bp.tablet
    padding: 4% 5% 5% 10%
  @media $bp.mobile
    height: 171px
    border-radius: 8px
    text-align: center
    background: url('./mobile-banner.png') no-repeat center / contain

  > .heading_1
    font-size: 64px
    line-height: 127%
    @media $bp.mobile
      font-size: 20px
      line-height: 127%
      margin: 28px auto 0
      max-width: 200px

  > p 
    line-height: 127%
    font-size: 36px
    @media $bp.mobile
      font-size: 16px
      margin-bottom: 12px

.salinas-library_column
  padding: 0 200px
  color: white
  @media $bp.desktop, $bp.tablet, $bp.mobile
    padding: 0
    margin: auto
  @media $bp.desktop
    width: calc(100% - 120px)
  @media $bp.tablet
    width: calc(100% - 60px)
  @media $bp.mobile
    width: calc(100% - 32px)

  .heading
    color: white
  
  a 
    color: white
    &:hover
      color: $text-accent
  
  .list-header__button
    background: rgba(255, 255, 255, 0.27)!important
    color: white!important

  .expandable-bubbles-list
    white-space: pre-wrap
    overflow: inherit
    padding: 0
    margin: 0

    @media $bp.mobile
      overflow-x: auto
      overflow-y: hidden
      white-space: nowrap
      padding-bottom: 12px

    .expandable-bubbles-list__item-link
      background: rgba(255, 255, 255, 0.27)!important
      color: white!important


  .list__body
    overflow-x: auto
    overflow-y: hidden
    white-space: nowrap
    padding-bottom: 12px

    .shelf_grid
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1)

      &:nth-child(3n)
        margin-right: 24px

    .shelf__footer
      background-color: #474747
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1)

.banner-logos_footer
  width: 84px
  height: 33px
