/* This file should be imported in a top-level javascript file instead of in stylus files */

/**
 * Kazimir
 */

@font-face
  font-family: Kazimir
  font-style: normal
  font-weight: normal
  font-display: fallback
  src: url('./fonts/kazimir/kazimir-light.woff2') format('woff2'),
       url('./fonts/kazimir/kazimir-light.woff') format('woff')

/**
 * Graphik
 */

@font-face
  font-family: Graphik
  font-style: normal
  font-weight: normal
  font-display: block
  src: url('./fonts/graphik/graphik-regular.woff2') format('woff2'),
       url('./fonts/graphik/graphik-regular.woff') format('woff')

@font-face
  font-family: Graphik
  font-style: normal
  font-weight: bold
  font-display: block
  src: url('./fonts/graphik/graphik-medium.woff2') format('woff2'),
       url('./fonts/graphik/graphik-medium.woff') format('woff')


@font-face
  font-family: GraphiBold
  font-style: normal
  font-weight: normal
  font-display: block
  src: url('./fonts/graphik/graphik-black.woff2') format('woff2'),
    url('./fonts/graphik/graphik-black.woff') format('woff')
