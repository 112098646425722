.alert
  position: fixed
  bottom: 24px
  left: @bottom
  z-index: 1101
  width: 95%
  max-width: 470px
  min-height: 80px
  box-sizing: border-box
  padding: 22px 50px 20px 20px
  background: white
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07)
  animation: slideDown .3s
  border-radius: 4px
  @media $bp.mobile
    bottom: 8px
    left: @bottom

  &__close-icon
    fill: $text-secondary-light
    cursor: pointer
    position: absolute
    top: 11px
    right: @top

    svg
      width: 13px
      height: @width

.alert__message
  $font-size-small()
  color: $text-primary
  display: flex
  align-content: center

  &-text
    margin-left: 20px
    display: block
    @media $bp.mobile
      margin-left: inherit

.alert__message a
  $link-underline()

.alert_success
.alert_error
.alert_feedback
  display: block

.alert__theme
  width: 8px
  height: 81px
  position: absolute
  top: 0
  left: 0

  &-icon
    width: 24px
    height: @width
    display: block

.alert_success
  >.alert__theme
    background: $action-success
  .alert__theme-icon
    stroke: $action-success
    &>svg>g>path
      fill: $action-success

.alert_error
  >.alert__theme
    background: $action-error
  .alert__theme-icon
    stroke: $action-error
    &>svg>g>path
      fill: $action-error

.alert_feedback
  >.alert__theme
    background: $action-accent
  .alert__theme-icon
    stroke: $action-accent
    &>svg>g>path
      fill: $action-accent

@keyframes slideDown
  0%
    transform: translateY(-56px)

  100%
    transform: translateY(0)
