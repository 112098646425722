@keyframes plus-button-spinning
  from
    transform: rotate(0deg)

  to
    transform: rotate(270deg)

.plus
  width: 48px
  height: 48px
  line-height: 1
  position: relative
  cursor: pointer

.plus:hover
  .plus__bar, .plus__bar_diagonal
    background-color: $action-accent

.plus_added
  .plus__arrow-down
    opacity: 1
    transform: none

  .plus__bars
    opacity: 0

.plus_not-added
  .plus__arrow-down
    transition-duration: 0s

  .context-menu
    pointer-events: none

.plus_loading
  pointer-events: none

  .plus__bars_diagonal
    transition: all 0.3s
    opacity: 1

  .plus__bars
    transition: all 0.3s
    animation: plus-button-spinning 1s linear infinite

  .plus__bar-1, .plus__bar-2
    width: 2px

  .plus__bar-3, .plus__bar-4
    height: 2px

.plus_loading_backwrds
  .plus__arrow-down
    transition-duration: 0s

.plus__bars
  position: absolute
  top: 16px
  left: 16px
  width: 16px
  height: 16px

.plus__bars_diagonal
  height: 16px
  width: 16px
  transform: rotate(45deg)
  opacity: 0

.plus__bar_diagonal
  display: inline-block
  background-color: $text-secondary
  position: absolute
  z-index: 1

.plus__bar
  position: absolute
  display: inline-block
  background-color: $text-secondary
  z-index: 1

.plus__bar-1
  height: 2px
  left: 0
  top: 7px
  width: 8px

.plus__bar-2
  height: 2px
  right: 0px
  top: 7px
  width: 8px

.plus__bar-3
  height: 8px
  left: 7px
  top: 0
  width: 2px

.plus__bar-4
  height: 8px
  left: 7px
  bottom: 0
  width: 2px

.plus__arrow-down
  position: absolute
  width: 100%
  height: 100%
  fill: $text-secondary
  opacity: 0
  transition: all 0.6s
  transform: translateY(-4px)
