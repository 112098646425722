.expandable-bubbles-list
  position: relative
  @media $bp.tablet, $bp.mobile
    margin: 0 8px

  &__items
    margin: 0
    padding: 0
    font-size: 0
    list-style: none
    white-space: nowrap
    overflow: hidden

  &__item
    display: inline-block
    margin-bottom: 8px
    vertical-align: top

    &:not(:last-child)
      margin-right: 8px

  &__item-link
    $bubble()

  &__button
    position: absolute
    top: 0
    right: 0
    width: 36px
    height: @width
    min-height: unset
    padding: 0
    border: 1px solid $text-secondary
    border-radius: 4px
    z-index: 2
    transition: all 0.3s

    svg
      width: 14px
      fill: $text-secondary
      transition: all 0.3s

    &.button_icon:not(.button_disabled):not(.button_loading):hover
      border: 1px solid darken($text-secondary, 20%)

      svg
        fill: darken($text-secondary, 20%)

  &__button-wrapper
    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      display: block
      width: 96px
      height: 40px
      background-image: linear-gradient(to right, rgba($base-default, 0), $base-default 50%, $base-default 100%)
      z-index: 1

  &_expanded
    .expandable-bubbles-list__items
      white-space: pre-wrap
      overflow: visible

    .expandable-bubbles-list__button-wrapper
      display: none
