.author
  display: flex
  position: relative

.author__pic
  flex-shrink: 0
  flex-grow: 0

.author__name
  $list-view-title-link()
  $font-size-small()
  color: $text-primary
  line-height: 1
  font-weight: normal
  margin: 1em 0
  text-decoration: none

.author_list
  $list-view()

  .author__info
    $list-view-info()

  .author__name
    $list-view-title()

.author_card
  .author__name
    margin-left: 8px
