.list__slider-container
  height: 232px

.list__carousel-container
  position: relative
  width: 100%
  margin: auto
  overflow-x: hidden

.list__carousel
  position: relative
  overflow: hidden
  width: 100%

.list__element-container
  width: 100%
  box-sizing: border-box

.list__carousel-item-container
  padding: 0 16px

.list
  &__body
    list-style: none
    padding: 0
    $reset-block-margins()

  .horizontal-scroller
    .shelf_grid
    .book_grid
    .audiobook_grid
    .comicbook_grid
      margin-bottom: 0

  .series_grid
    vertical-align: top
    margin-right: 24px

    &:last-child
      margin-right: 0

    @media $bp.tablet, $bp.mobile
      &:first-child
        margin-left: 8px

      &:last-child
        margin-right: 8px

  .shelf_grid
    vertical-align: top
    margin-right: 24px
    margin-bottom: 32px

    &.shelf_300
      margin-right: 40px

    &.shelf_288:not(:last-child)
      margin-right: 8px

    @media $bp.desktopLarge, $bp.desktopMedium, $bp.desktop

      &:nth-child(3n)
        margin-right: 0

  .book_grid
  .audiobook_grid
  .comicbook_grid

    &:not(:last-child)
      margin-right: 24px

  .book_grid.book_88
  .audiobook_grid.audiobook_88
  .comicbook_grid.comicbook_88

    &:not(:last-child)
      margin-right: 8px

  .book_grid.book_56
  .audiobook_grid.audiobook_56
  .comicbook_grid.comicbook_56

    &:not(:last-child)
      margin-right: 8px

  .book_list
  .audiobook_list
  .comicbook_list
  .shelf_list
  .user_list
  .author_list
  .series

    &:not(:last-child)
      margin-bottom: 16px

.list-header-box
  display: flex
  justify-content: space-between
  &.reverse
    flex-direction: row-reverse

.shelf_in-list
  display: inline-block
