.payment-system
  &__icon
    width: 50px
    height: 24px
    margin: auto

    &_cards
      background: url('./cards.svg?file') no-repeat center / contain

    &_apple_pay
      background: url('./apple-pay.svg?file') no-repeat center / contain

    &_google_pay
      background: url('./google-pay.svg?file') no-repeat center / contain
      
    &_paypal
      background: url('./paypal.svg?file') no-repeat center / contain

    &_link
      background: url('./link.svg?file') no-repeat center / contain

.gift-payment__inner
  .payment-system__icon_link       
    background: url('./link-gifts.svg?file') no-repeat center / contain
  .payment-system__icon_google_pay      
    background: url('./google-pay-gifts.svg?file') no-repeat center / contain
  .payment-system__icon_apple_pay   
    background: url('./apple-pay-gifts.svg?file') no-repeat center / contain
  .payment-system__icon_link,
  .payment-system__icon_google_pay,
  .payment-system__icon_apple_pay 
    @media $bp.mobile
      height: 20px   


.subscription-form__radio-wrapper
  .toggler__radio-option.no-checker
    &::before
      display: none
    margin-right: 16px
    margin-bottom: 0
    box-sizing: border-box
    border-radius: 8px;
    display: inline-block
    color: $text-primary
    border: 1px solid $base-line-secondary
    background-color: white
    text-transform: uppercase
    padding: 15px 0
    height: 58px
    width: 100%
    min-height: inherit
    @media $bp.mobile
      height: 48px
    
    &:hover
      border-color: $action-accent
      cursor: pointer

  .toggler__radio-option_selected.no-checker
    border-color: $action-accent
    &:hover
      cursor: default
