$layout-sizes = 1 2 3 4 5 6 7 8 9 10 12 13 14

.layout-column
  display: block
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent

  @media $bp.mobile
    max-width: calc(100% - 16px)
    margin: auto

for size in $layout-sizes
  .layout-column_{size}
    $column(size)

.layout-column_centered
    margin-left: auto
    margin-right auto

.layout-column_single
  position: relative
  width: 100%

  .layout-column__inner
    width: 624px
    margin: 0 auto

    @media $bp.mobile
      width: auto
      margin-left: 8px
      margin-right: 8px

.layout-column_auto-width
  display: flex
  flex-wrap: wrap
  align-content: flex-start
  justify-content: center
  margin: 0 auto

  .layout-column__inner
    width: auto
    margin: 0

    @media $bp.oneColumn
      width: 100%

.button__back
  $font-size-small()
  display: inline-block
  margin-bottom: 32px
  color: $text-secondary
  cursor: pointer
