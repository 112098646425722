.progressive-cover
  display: inline-block
  position: relative
  height: 100%
  background-repeat: no-repeat
  background-position: center
  background-size: 100% 100%
  vertical-align: top

  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    box-shadow: 0 0 0 2px rgba(black, 0.04)

.progressive-image
  opacity: 0

  &_entered
    opacity: 1
    transition: opacity .2s
