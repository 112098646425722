.cookies-message
  color: white
  position: fixed
  box-sizing: border-box
  right: 50%
  bottom: 14px
  transform: translate(50%, 0)
  width: 784px
  min-height: 100px
  border-radius: 8px
  padding: 34px 27px 24px 16px
  display: flex
  flex-direction: column
  background: $text-primary
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1)
  z-index: 900
  font-size: 14px
  line-height: 1.4

  @media $bp.tablet, $bp.mobile
    width: 100%
    bottom: 0
    border-radius: 0

  @media $bp.mobile
    transform: none
    right: auto
    padding: 4px 8px

  &__text
    padding-bottom 12px

  &__image
    position: absolute
    left: 34px
    top: -16px
    display: block
    width: 48px
    height: auto

    @media $bp.mobile
      display: none

  &__link
    text-decoration: underline
    cursor: pointer
    color: white

    &:hover
      color: $text-accent
      transition: color 0.2s

  &__checkboxes
    & .checkbox
      font-size: 12px

      & > input:disabled ~ label
        opacity: 0.7

      @media $bp.mobile
        margin-bottom: 8px

      &:last-child
        margin-bottom: 0

  &__buttons
    display: flex
    gap: 10px

    @media $bp.mobile
      flex-direction: column

  &__button
    text-transform: uppercase
    font-weight: bold
    font-size: 12px
    margin-top: 16px

  &__button_primary
    $btn-primary()
    width auto
    font-size: 12px
    margin-top: 16px
    background-color: $action-accent

    &:not(&_disabled):not(&_loading):hover
      color: white
      opacity: 0.9

    @media $bp.mobile
      white-space: nowrap
      width: 248px
      margin: 8px auto 0

  &__button_secondary
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 12px 16px
    text-decoration: underline
    color: $text-accent
    cursor: pointer

    &:hover
      color: white
      transition: color 0.2s

  .checkbox
    .checkbox__box
      border: none

    input:checked + label .checkbox__box
      background: $action-accent

    input + label .checkbox__label
      opacity: 0.7
      color: white

    input:checked + label .checkbox__label
      color: white
      opacity: 1
