html
body
  height: 100%
  margin: 0
  padding: 0
  text-size-adjust: 100%

body
  font: 16px/1.4 $font-graphik
  -webkit-font-smoothing: antialiased
  background: $base-default
  color: $text-primary
  overflow-y: scroll

img
  border: none

#nprogress .bar
  background: $action-accent
  position: fixed
  z-index: 1031
  top: 0
  left: 0
  width: 100%
  height: 2px

#nprogress .spinner
  display: none

.no-overflow
  overflow: hidden
  position: relative
  height: 100%

.gifts_wrapper
  background: $base-primary-dark
  min-height: 100%

  .heading_1,
  .copyright
    color: white
