.subscription-button
  $btn-primary()

  &_huge
    $btn-huge()

  &_small
    $font-size-x-small()
    border-radius: 78px
    padding: 4px 16px
    min-height: 0

  &_banner
    background-color: $text-success
    border-radius: 0
    text-align: left
    display: flex
    align-items: center
    justify-content: space-between
    @media $bp.tablet
      display: none

    .banner__right-controls
      display: flex
      align-items: center

    .banner-svg
      height: 19px
      margin-left: 8px

      svg path
        stroke: white
        stroke-opacity: 1
