.mtn-phone-input-wrapper {
  display: flex;
  align-items: center;
}

.mtn-phone-input-code {
  position: absolute;
  z-index: 1;
  margin-left: 16px;
  padding: 0 16px;
  line-height: 32px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  color: black;
}

.error {
  $font-size-x-small()
  color: $text-error
  background: rgba(255, 255, 255, 0.7)
  border-radius: 2px
  max-width: 338px
  margin: auto

  @media screen and (max-width: 374px) {
    width: calc(100% - 48px)
  }
}

.mtn-phone-input {
  position: relative;
  width: 100%;
  height: 56px;
  padding: 16px;
  padding-left: 95px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  outline: none;
  font-size: 17px;
}
