.footer__storebuttons
  display: flex
  margin-bottom: 8px

  @media $bp.mobile
    flex-direction: column

.footer__storebuttons_block
  flex-direction: column

.footer__storebuttons_inline
  flex-direction: row
  flex-wrap: wrap

  @media $bp.mobile
    flex-direction: column
    width: max-content
    max-width: 170px

.footer__storebutton
  display: flex
  padding-right: 8px
  margin-bottom: 8px

  @media $bp.mobile
    padding-right: 0
