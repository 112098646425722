.authors-list
  text-align: left

  &_underlined a
    $link-underline()

.authors-list__author
  margin-right: 0.5em

.authors-list_compact
  $font-size-small()
  display: inline-block
  max-width: 100%
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  font-weight: normal
  line-height: 20px
  margin: 0
  lineHeight = 1.3
  max-height: (14 * 1 * lineHeight)px // font-size * line-height * number of lines

  .authors-list__author
    border: none
