.phone-input
  color: $text-primary
  background-color: white
  border-radius: 4px
  border: 1px solid $base-line-secondary
  margin: 0 0 16px

  &_error
    border-color: $action-error

  &__wrapper
    display: flex

  &__code
    cursor: pointer
    color: $text-secondary
    user-select: none
    padding: 13px 2ex 13px 20px

    &:hover
      color: inherit

  &__code_readonly
    cursor: default
    color: $text-primary

  &__code::after
    content: ''
    display: inline-block
    margin-left: 1ex
    transform: rotate(-135deg)
    position: relative
    bottom: 2px
    width: 5px
    height: 5px;
    border-top: 1px solid
    border-left: 1px solid
    border-color: inherit
    transition: transform 0.5s

  &__code_shown-dropdown::after
    transform: rotate(45deg)

  &__code_readonly::after
    display: none

  &__code__value-wrapper
    padding: 0px 8px
    background-color: rgba(0, 0, 0, 0.07)
    border-radius: 16px
    display: flex
    align-items: center

  &__input
    flex: 1
    width: 100%
    font: 1rem/1 $font-museo
    margin: 1px
    padding: 13px 19px 13px 2ex
    color: inherit
    border: none

    &:focus
      outline: none

    &::placeholder
      color: $text-secondary

    &:-webkit-autofill
      -webkit-box-shadow: 0 0 0 50px white inset
      -webkit-text-fill-color: $text-primary

  &__select
    box-sizing: border-box
    width: 231px
    max-height: 306px
    list-style: none
    background: white
    border-radius: 4px
    border: 1px solid $base-line-secondary
    padding: 16px 24px
    margin: 8px 0 0
    overflow: auto
    position: absolute
    left: 0
    z-index: 1
    -webkit-overflow-scrolling: touch

    @media $bp.mobile
      position: static
      flex: 1
      max-height: 60vh
      padding-top: 0
      border: none

  &__select-wrapper
    @media $bp.mobile
      display: flex
      flex-direction: column
      align-items: center
      position: fixed
      background: white
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 1

  &__header
    $font-size-medium()
    display: none
    color: $text-primary
    font-weight: bold
    text-align: center
    padding: 16px 0 4px

    @media $bp.mobile
      display: block

  &__back
    position: absolute
    cursor: pointer
    display: none
    width: 40px
    height: @width
    top: 8px
    left: 0
    fill: $text-secondary

    @media $bp.mobile
      display: block

.phone-input__select-wrapper
  position: relative

.phone-input-option
  $font-size-medium()
  line-height: 2
  cursor: pointer
  white-space: nowrap

  &:hover
    color: $text-accent

  &:focus, &:active, &_active
    color: darken($text-accent, 30%)
    outline: none

  &__name
  &__code
    display: inline-block
    vertical-align: top

  &__name
    width: 70%
    text-align: left
    overflow: hidden
    text-overflow: ellipsis

  &__code
    width: 30%
    text-align: right

.yettel_background 
  .auth
    .button_submit
      padding: 14px 32px
      border-radius: 28px
      background: #002340
      color: #B4FF00
