.credential-input
  position: relative

.credential-input__loader
.credential-input__userpic
  position: absolute
  top: 50%
  right: 16px
  width: 24px
  height: 24px

.credential-input__loader
  margin-top: -10px

.credential-input__userpic
  margin-top: -12px
  border-radius: 50%
