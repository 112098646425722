.social-userinfo-plate
  display: flex
  height: 48px
  align-items: center
  border: 1px solid $base-line-secondary
  border-radius: 4px
  padding-left: 16px
  padding-right: 12px

  &__icon
    flex: 0 0 24px

    .SVGInline
      font-size: 34px

  &__name
    flex 1 0 auto
    font-weight: bold
    text-align: center
    color: $text-primary

  &__avatar
    width: 24px
    height: @width
    border-radius: 50%
    overflow: hidden
    flex: 0 0 24px

    img
      max-width: 100%
      max-height: 100%
