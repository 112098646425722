$book-cover-dimensions = {
  "56": 78,
  "88": 124,
  "120": 176,
  "176": 247,
  "208": 290
}

for width, height in $book-cover-dimensions
  .book-cover_{width}
    width: convert(width)px
    height: (height)px

.book-cover
  display: table
  overflow: hidden
  user-select: none

  .book-cover__wrapper
    display: table-cell
    font-size: 0
    vertical-align: middle
