for size in $cover-sizes
  .audiobook_{size}
    width: (size)px

.audiobook
  position: relative

.audiobook_hoverable
  &:hover
    box-shadow: 0 0 0 1px $base-line-accent

.audiobook__authors
  position: relative
  z-index: 1

.audiobook__title
  $list-view-title-link()
  display: block

.audiobook__cover
  position: relative
  display: flex
  flex-grow: 0
  flex-shrink: 0

.audiobook__description
  line-height: .8

.audiobook_list
  list-style-type: none
  $list-view()

  .audiobook__description
    $list-view-info()
    min-width: 0

  .audiobook__title
    $list-view-title()

  .audiobook__reading-status
    $list-view-reading-status()

.audiobook_grid
  $grid-view()

  .audiobook__title
    $grid-view-title()

  .audiobook__description
    $grid-view-description()

  .audiobook__reading-status
    $grid-view-reading-status()

.audiobook__position
  $position-label()
