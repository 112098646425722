for size in $cover-sizes
  .book_{size}
    width: (size)px

.book
  position: relative

.book_hoverable
  &:hover
    box-shadow: 0 0 0 1px $base-line-accent

.book__authors
  position: relative
  z-index: 1

.book__title
  $list-view-title-link()
  display: block

.book__cover
  position: relative

.book-cover_unavailable
  .progressive-cover
    opacity: 0.6

.book__unavailable
  &_wrapper
    position: absolute
    bottom: 8px
    z-index: 2
  &_text
    white-space: normal
    text-align: center
    display: block
    max-width: 100px
    width: fit-content
    width: -moz-fit-content
    margin: auto
    text-transform: uppercase
    $font-size-xx-small()
    font-weight: 500
    color: white
    padding: 4px
    background: black
    opacity: 0.6
    border-radius: 100px

.book__description
  line-height: .8

.book_list
  list-style-type: none
  $list-view()
  padding: 12px 8px 12px 16px

  .book__description
    $list-view-info()
    min-width: 0

  .book__title
    $list-view-title()

  .book__reading-status
    $list-view-reading-status()

.book_grid
  $grid-view()

  .book__title
    $grid-view-title()

  .book__description
    $grid-view-description()

  .book__reading-status
    $grid-view-reading-status()

.book__position
  $position-label()

.book__new-episode-label
  position: relative

.book__new-episode-label:before
  content: ''
  position: absolute
  right: -4px;
  top: -8px;
  z-index: 1;
  width: 15px
  height: 15px
  border: 1px solid $action-error
  border-radius: 50%
  background-color: $action-error
