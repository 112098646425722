$avatar-sizes = 24 32 40 88 124 176

.user-avatar
  border-radius: 50%
  overflow: hidden
  user-select: none
  display: block
  -webkit-mask-image: -webkit-radial-gradient(white, black)

  img
    max-width: 100%
    max-height: 100%

for size in $avatar-sizes
  .user-avatar_{size}
    width: (size)px
    height: @width
