.dropdown
  position: absolute
  z-index: 1100
  background: white
  border-radius: 4px
  border: 1px solid rgba($base-line-secondary, 0.5)
  color: $text-primary
  margin: 8px 0 0
  padding: 9px 0 14px
  text-align: left
  list-style: none
  will-change: transform
  transition: opacity 0.2s ease-in, transform 0.2s ease-in, visibility 0.2s ease-in
  min-width: 120px
  max-width: 470px
  cursor: auto

  > .header__nav-tab
    padding: 8px 0

  .no-js &
    display: none

  &:not(.same_size)
    @media $bp.mobile
      transition: none
      max-width: 100%
      width: 100%

  &:before
    content: ''
    position: absolute
    width: 10px
    height: 10px
    background: inherit
    border: inherit
    transform: rotate(45deg)

    @media $bp.mobile
      display: none

  &_bottom-center:before
  &_bottom-right:before
  &_bottom-left:before
    top: 100%
    border-width: 0 1px 1px 0
    margin: -4px 0 0 -5px

  &_top-center:before
  &_top-right:before
  &_top-left:before
  &_top-right-offset:before
    top: 0
    border-width: 1px 0 0 1px
    margin: -6px 0 0 -6px

  &_bottom-center:before
  &_top-center:before
    left: 50%

  &_bottom-right:before
  &_top-right:before
    right: 24px

  &_top-right-offset:before
    right: 14px

  &_bottom-left:before
  &_top-left:before
    left: 24px

  &_hidden
    left: -1000%
    opacity: 0
    visibility: hidden
    transform: translateY(-10px)

    @media $bp.mobile
      display: none

  &:not(.same_size)
    @media $bp.mobile
      position: fixed
      bottom: 0
      left: 0
      right: 0
      padding: 0
      border-radius: 8px 8px 0 0
      box-shadow: 0 0 0 9999px rgba(black, 0.7)

  &__item
    $font-size-x-small()
    text-align: left
    line-height: 1
    padding: 9px 20px 4px 14px
    font-weight: bold
    cursor: pointer
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis

    &:hover
      color: $text-accent

      svg
        fill: $text-accent

    @media $bp.mobile
      $font-size-medium()
      padding: 16px 0 16px 8px
      margin: 0 16px
      border-bottom: 1px solid rgba($base-line-secondary, 0.5)
      text-transform: none
      font-weight: normal
      text-align: left

      &:last-child
        border: none

  &__item-mark
  &__item-icon
    display: inline-block
    vertical-align: middle
    margin-right: 12px
    width: 24px
    height: @width
    fill: $text-secondary

    @media $bp.mobile
      fill: $text-accent

  &__item-mark
    fill: $text-accent
    margin: -4px 0 0 8px

  &__item-title
    display: inline
    text-align: left
    vertical-align: middle
    text-transform: none
