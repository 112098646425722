.series-cover
  padding-left: 12px
  padding-bottom: 12px

.series-cover__wrapper
  position: relative

  .progressive-cover,
  .progressive-cover::after,
  .progressive-image
    border-radius: 4px

.series-cover__substrate
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%

  &::before,
  &::after
    content: ''
    display: block
    width: calc(100% - 2px)
    height: calc(100% - 2px)
    background-color: white
    border-radius: 4px
    border: 1px solid #a38b80

  &::after
    position: absolute
    bottom: -6px
    left: -6px

  &::before
    position: absolute
    bottom: -12px
    left: -12px

.series-cover__play-icon
  $play-icon()
